import { __assign } from "tslib";
import { h, createRef } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import useCoreContext from '../../../core/Context/useCoreContext';
import CompanyDetails from '../CompanyDetails';
import PersonalDetails from '../PersonalDetails';
import Address from '../Address';
import Checkbox from '../FormFields/Checkbox';
import ConsentCheckbox from '../FormFields/ConsentCheckbox';
import { getInitialActiveFieldsets } from './utils';
import './OpenInvoice.scss';
var fieldsetsSchema = ['companyDetails', 'personalDetails', 'billingAddress', 'deliveryAddress'];
export default function OpenInvoice(props) {
    var countryCode = props.countryCode, visibility = props.visibility;
    var i18n = useCoreContext().i18n;
    var initialActiveFieldsets = getInitialActiveFieldsets(fieldsetsSchema, visibility);
    var _a = useState(__assign(__assign({}, initialActiveFieldsets), { deliveryAddress: false })), activeFieldsets = _a[0], setActiveFieldsets = _a[1];
    var fieldsetsRefs = fieldsetsSchema.reduce(function (acc, fieldset) {
        acc[fieldset] = createRef();
        return acc;
    }, {});
    var checkFieldsets = function () { return Object.keys(activeFieldsets).every(function (fieldset) { return !activeFieldsets[fieldset] || !!valid[fieldset]; }); };
    var hasConsentCheckbox = !!props.consentCheckboxLabel;
    var _b = useState(__assign(__assign({}, props.data), (hasConsentCheckbox && { consentCheckbox: false }))), data = _b[0], setData = _b[1];
    var _c = useState({}), errors = _c[0], setErrors = _c[1];
    var _d = useState({}), valid = _d[0], setValid = _d[1];
    var _e = useState('ready'), status = _e[0], setStatus = _e[1];
    this.setStatus = function (newStatus) {
        setStatus(newStatus);
    };
    useEffect(function () {
        var fieldsetsAreValid = checkFieldsets();
        var consentCheckboxValid = !hasConsentCheckbox || !!valid.consentCheckbox;
        var isValid = fieldsetsAreValid && consentCheckboxValid;
        props.onChange({ data: data, isValid: isValid });
    }, [data, valid, errors]);
    var handleFieldset = function (key) { return function (state) {
        setData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[key] = state.data, _a)));
        });
        setValid(function (prevValid) {
            var _a;
            return (__assign(__assign({}, prevValid), (_a = {}, _a[key] = state.isValid, _a)));
        });
    }; };
    var handleSeparateDeliveryAddress = function (e) {
        setActiveFieldsets(function (prevActiveFields) { return (__assign(__assign({}, prevActiveFields), { deliveryAddress: e.target.checked })); });
    };
    var handleConsentCheckbox = function (e) {
        var checked = e.target.checked;
        setData(function (prevData) { return (__assign(__assign({}, prevData), { consentCheckbox: checked })); });
        setValid(function (prevValid) { return (__assign(__assign({}, prevValid), { consentCheckbox: checked })); });
        setErrors(function (prevErrors) { return (__assign(__assign({}, prevErrors), { consentCheckbox: !checked })); });
    };
    this.showValidation = function () {
        fieldsetsSchema.forEach(function (fieldset) {
            if (fieldsetsRefs[fieldset].current)
                fieldsetsRefs[fieldset].current.showValidation();
        });
        setErrors(__assign({}, (hasConsentCheckbox && { consentCheckbox: !data.consentCheckbox })));
    };
    return (h("div", { className: "adyen-checkout__open-invoice" },
        activeFieldsets.companyDetails && (h(CompanyDetails, { data: data.companyDetails, label: "companyDetails", onChange: handleFieldset('companyDetails'), ref: fieldsetsRefs.companyDetails, visibility: visibility.companyDetails })),
        activeFieldsets.personalDetails && (h(PersonalDetails, { data: data.personalDetails, requiredFields: props.personalDetailsRequiredFields, label: "personalDetails", onChange: handleFieldset('personalDetails'), ref: fieldsetsRefs.personalDetails, visibility: visibility.personalDetails })),
        activeFieldsets.billingAddress && (h(Address, { allowedCountries: props.allowedCountries, countryCode: countryCode, data: data.billingAddress, label: "billingAddress", onChange: handleFieldset('billingAddress'), ref: fieldsetsRefs.billingAddress, visibility: visibility.billingAddress })),
        initialActiveFieldsets.deliveryAddress && (h(Checkbox, { label: i18n.get('separateDeliveryAddress'), classNameModifiers: ['separateDeliveryAddress'], name: "separateDeliveryAddress", onChange: handleSeparateDeliveryAddress })),
        activeFieldsets.deliveryAddress && (h(Address, { allowedCountries: props.allowedCountries, countryCode: countryCode, data: data.deliveryAddress, label: "deliveryAddress", onChange: handleFieldset('deliveryAddress'), ref: fieldsetsRefs.deliveryAddress, visibility: visibility.deliveryAddress })),
        hasConsentCheckbox && (h(ConsentCheckbox, { data: data, errorMessage: !!errors.consentCheckbox, label: props.consentCheckboxLabel, onChange: handleConsentCheckbox })),
        props.showPayButton && props.payButton({ status: status, label: i18n.get('confirmPurchase') })));
}
