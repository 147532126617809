import { __rest } from "tslib";
import { h } from 'preact';
import './GiftcardResult.scss';
import getImage from '../../../utils/get-image';
function GiftcardResult(_a) {
    var i18n = _a.i18n, loadingContext = _a.loadingContext, paymentMethodType = _a.paymentMethodType, props = __rest(_a, ["i18n", "loadingContext", "paymentMethodType"]);
    return (h("div", { className: "adyen-checkout__giftcard-result" },
        h("div", { className: "adyen-checkout__giftcard-result__header" },
            h("div", { className: "adyen-checkout__giftcard-result__header__title" },
                h("span", { className: "adyen-checkout__payment-method__image__wrapper adyen-checkout__payment-method__image__wrapper--loaded" },
                    h("img", { alt: paymentMethodType, className: "adyen-checkout__payment-method__image", src: getImage({ loadingContext: loadingContext })(paymentMethodType) })),
                h("span", { className: "adyen-checkout__giftcard-result__name", "aria-hidden": "true" },
                    "\u2022\u2022\u2022\u2022 ",
                    props.lastFour))),
        h("ul", { className: "adyen-checkout__giftcard-result__balance" },
            h("li", { className: "adyen-checkout__giftcard-result__balance__item" },
                h("span", { className: "adyen-checkout__giftcard-result__balance__title" }, "Deducted amount:"),
                h("span", { className: "adyen-checkout__giftcard-result__balance__value adyen-checkout__giftcard-result__balance__value--amount" }, i18n.amount(props.deductedAmount.value, props.deductedAmount.currencyCode))),
            h("li", { className: "adyen-checkout__giftcard-result__balance__item adyen-checkout__giftcard-result__balance__item--remaining-balance" },
                h("span", { className: "adyen-checkout__giftcard-result__balance__title" }, "Remaining balance:"),
                h("span", { className: "adyen-checkout__giftcard-result__balance__value" }, i18n.amount(props.remainingBalance.value, props.remainingBalance.currencyCode))))));
}
export default GiftcardResult;
