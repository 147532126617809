import { __assign } from "tslib";
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import PaypalButtons from './PaypalButtons';
import Spinner from '../../internal/Spinner';
import useCoreContext from '../../../core/Context/useCoreContext';
import { getPaypalUrl } from '../utils';
import Script from '../../../utils/Script';
export default function PaypalComponent(props) {
    var i18n = useCoreContext().i18n;
    var _a = useState('pending'), status = _a[0], setStatus = _a[1];
    this.setStatus = function (newStatus) {
        setStatus(newStatus);
    };
    var handleComplete = function (data) {
        setStatus('processing');
        props.onComplete(data);
    };
    var handlePaypalLoad = function () {
        setStatus('ready');
    };
    useEffect(function () {
        var src = getPaypalUrl(props);
        var script = new Script(src);
        script.load().then(handlePaypalLoad);
        return function () {
            script.remove();
        };
    }, []);
    if (status === 'pending') {
        return (h("div", { className: "adyen-checkout__paypal" },
            h("div", { className: "adyen-checkout__paypal__status adyen-checkout__paypal__status--pending" },
                h(Spinner, null))));
    }
    if (status === 'processing') {
        return (h("div", { className: "adyen-checkout__paypal" },
            h("div", { className: "adyen-checkout__paypal__status adyen-checkout__paypal__status--processing" },
                h(Spinner, { size: "medium", inline: true }),
                " ",
                i18n.get('paypal.processingPayment'))));
    }
    return (h("div", { className: "adyen-checkout__paypal" },
        h(PaypalButtons, __assign({}, props, { onComplete: handleComplete, paypalRef: window.paypal }))));
}
