import { Fragment, h } from 'preact';
import useCoreContext from '../../../../core/Context/useCoreContext';
export default function ConsentCheckboxLabel(props) {
    var i18n = useCoreContext().i18n;
    var linkText = i18n.get('paymentConditions');
    var translationString = i18n.get('afterPay.agreement');
    var _a = translationString.split('%@'), textBeforeLink = _a[0], textAfterLink = _a[1];
    if (textBeforeLink && textAfterLink) {
        return (h(Fragment, null,
            textBeforeLink,
            h("a", { className: "adyen-checkout__link", target: "_blank", rel: "noopener noreferrer", href: props.url }, linkText),
            textAfterLink));
    }
    return h("span", { className: "adyen-checkout__checkbox__label" }, i18n.get('privacyPolicy'));
}
