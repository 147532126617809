import { __assign, __extends } from "tslib";
import QRLoaderContainer from '../helpers/QRLoaderContainer';
var SwishElement = /** @class */ (function (_super) {
    __extends(SwishElement, _super);
    function SwishElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SwishElement.prototype.formatProps = function (props) {
        return __assign({ shouldRedirectOnMobile: true, delay: 2000, countdownTime: 15, instructions: 'swish.pendingMessage' }, _super.prototype.formatProps.call(this, props));
    };
    SwishElement.type = 'swish';
    return SwishElement;
}(QRLoaderContainer));
export default SwishElement;
