import { __spreadArrays } from "tslib";
import { h } from 'preact';
import Voucher from '../../../internal/Voucher';
import getImage from '../../../../utils/get-image';
import useCoreContext from '../../../../core/Context/useCoreContext';
var MultibancoVoucherResult = function (props) {
    var _a = useCoreContext(), i18n = _a.i18n, loadingContext = _a.loadingContext;
    var entity = props.entity, reference = props.reference, expiresAt = props.expiresAt, merchantReference = props.merchantReference, totalAmount = props.totalAmount, paymentMethodType = props.paymentMethodType, downloadUrl = props.downloadUrl;
    var voucherDetails = __spreadArrays((entity ? [{ label: i18n.get('voucher.entity'), value: entity }] : []), (expiresAt ? [{ label: i18n.get('voucher.expirationDate'), value: i18n.date(expiresAt) }] : []), (merchantReference ? [{ label: i18n.get('voucher.shopperReference'), value: merchantReference }] : []));
    return (h(Voucher, { amount: totalAmount && i18n.amount(totalAmount.value, totalAmount.currency), barcode: null, copyBtn: true, downloadUrl: downloadUrl, imageUrl: getImage({ loadingContext: loadingContext })(paymentMethodType), introduction: i18n.get('voucher.introduction'), paymentMethodType: 'multibanco', reference: reference, voucherDetails: voucherDetails }));
};
export default MultibancoVoucherResult;
