var noop = function () { };
export function configureCallbacks(callbacksObj) {
    if (callbacksObj === void 0) { callbacksObj = {}; }
    // --
    this.callbacks.onLoad = callbacksObj.onLoad ? callbacksObj.onLoad : noop;
    this.callbacks.onConfigSuccess = callbacksObj.onConfigSuccess ? callbacksObj.onConfigSuccess : noop;
    this.callbacks.onFieldValid = callbacksObj.onFieldValid ? callbacksObj.onFieldValid : noop;
    this.callbacks.onAllValid = callbacksObj.onAllValid ? callbacksObj.onAllValid : noop;
    this.callbacks.onBrand = callbacksObj.onBrand ? callbacksObj.onBrand : noop;
    this.callbacks.onError = callbacksObj.onError ? callbacksObj.onError : noop;
    this.callbacks.onFocus = callbacksObj.onFocus ? callbacksObj.onFocus : noop;
    this.callbacks.onBinValue = callbacksObj.onBinValue ? callbacksObj.onBinValue : noop;
    this.callbacks.onAutoComplete = callbacksObj.onAutoComplete ? callbacksObj.onAutoComplete : noop;
}
