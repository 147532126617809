import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import OxxoVoucherResult from './components/OxxoVoucherResult';
import CoreProvider from '../../core/Context/CoreProvider';
var OxxoElement = /** @class */ (function (_super) {
    __extends(OxxoElement, _super);
    function OxxoElement() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleRef = function (ref) {
            _this.componentRef = ref;
        };
        return _this;
    }
    Object.defineProperty(OxxoElement.prototype, "isValid", {
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    OxxoElement.prototype.formatProps = function (props) {
        return __assign(__assign({}, props), { name: 'Oxxo' });
    };
    /**
     * Formats the component data output
     */
    OxxoElement.prototype.formatData = function () {
        return {
            paymentMethod: {
                type: this.props.type || OxxoElement.type
            }
        };
    };
    OxxoElement.prototype.render = function () {
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext }, this.props.reference ? (h(OxxoVoucherResult, __assign({ ref: this.handleRef }, this.props))) : (this.payButton(__assign(__assign({}, this.props), { classNameModifiers: ['standalone'], label: this.props.i18n.get('continueTo') + " " + this.props.name, onClick: this.submit })))));
    };
    OxxoElement.type = 'oxxo';
    return OxxoElement;
}(UIElement));
export { OxxoElement };
export default OxxoElement;
