var ApplePayService = /** @class */ (function () {
    function ApplePayService(paymentRequest, options) {
        var _this = this;
        this.session = new ApplePaySession(options.version, paymentRequest);
        this.session.onvalidatemerchant = function (event) { return _this.onvalidatemerchant(event, options.onValidateMerchant); };
        this.session.onpaymentauthorized = function (event) { return _this.onpaymentauthorized(event, options.onPaymentAuthorized); };
        this.session.oncancel = function (event) { return _this.oncancel(event, options.onCancel); };
        if (typeof options.onPaymentMethodSelected === 'function') {
            this.session.onpaymentmethodselected = function (event) { return _this.onpaymentmethodselected(event, options.onPaymentMethodSelected); };
        }
        if (typeof options.onShippingContactSelected === 'function') {
            this.session.onshippingcontactselected = function (event) { return _this.onshippingcontactselected(event, options.onShippingContactSelected); };
        }
        if (typeof options.onShippingMethodSelected === 'function') {
            this.session.onshippingmethodselected = function (event) { return _this.onshippingmethodselected(event, options.onShippingMethodSelected); };
        }
    }
    /**
     * Begins the merchant validation process.
     * When this method is called, the payment sheet is presented and the merchant validation process is initiated.
     * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778001-begin}
     */
    ApplePayService.prototype.begin = function () {
        return this.session.begin();
    };
    /**
     * An event handler that is called when the payment sheet is displayed.
     * Use this attribute to request and return a merchant session.
     * @param event - An ApplePayValidateMerchantEvent object (contains validationURL)
     * @param onValidateMerchant - A promise implemented by the merchant that will resolve with the merchantSession
     * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/providing_merchant_validation}
     */
    ApplePayService.prototype.onvalidatemerchant = function (event, onValidateMerchant) {
        var _this = this;
        new Promise(function (resolve, reject) { return onValidateMerchant(resolve, reject, event.validationURL); })
            .then(function (response) {
            _this.session.completeMerchantValidation(response);
        })
            .catch(function (error) {
            console.error(error);
            _this.session.abort();
        });
    };
    /**
     * An event handler that is called when the user has authorized the Apple Pay payment with Touch ID, Face ID, or passcode.
     * The onpaymentauthorized function must complete the payment and respond by calling completePayment before the 30 second timeout.
     * @param event - The event parameter contains the payment (ApplePayPayment) attribute.
     * @param onPaymentAuthorized - A promise that will complete the payment when resolved. Use this promise to process the payment.
     * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778020-onpaymentauthorized}
     */
    ApplePayService.prototype.onpaymentauthorized = function (event, onPaymentAuthorized) {
        var _this = this;
        return new Promise(function (resolve, reject) { return onPaymentAuthorized(resolve, reject, event); })
            .then(function () {
            _this.session.completePayment(ApplePaySession.STATUS_SUCCESS);
        })
            .catch(function () {
            _this.session.completePayment(ApplePaySession.STATUS_FAILURE);
        });
    };
    /**
     * An event handler that is called when a new payment method is selected.
     * The onpaymentmethodselected function must resolve before the 30 second timeout
     * @param event - The event parameter contains the payment (ApplePayPayment) attribute.
     * @param onPaymentMethodSelected - A promise that will complete the payment when resolved. Use this promise to process the payment.
     * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778013-onpaymentmethodselected}
     */
    ApplePayService.prototype.onpaymentmethodselected = function (event, onPaymentMethodSelected) {
        var _this = this;
        return new Promise(function (resolve, reject) { return onPaymentMethodSelected(resolve, reject, event); })
            .then(function (paymentMethodUpdate) {
            _this.session.completePaymentMethodSelection(paymentMethodUpdate);
        })
            .catch(function (paymentMethodUpdate) {
            _this.session.completePaymentMethodSelection(paymentMethodUpdate);
        });
    };
    /**
     * An event handler that is called when a new payment method is selected.
     * The onpaymentmethodselected function must resolve before the 30 second timeout
     * @param event - The event parameter contains the shippingContact attribute.
     * @param onShippingContactSelected - A promise that will complete the selection of a shipping contact with an update.
     * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778009-onshippingcontactselected}
     */
    ApplePayService.prototype.onshippingcontactselected = function (event, onShippingContactSelected) {
        var _this = this;
        return new Promise(function (resolve, reject) { return onShippingContactSelected(resolve, reject, event); })
            .then(function (shippingContactUpdate) {
            _this.session.completeShippingContactSelection(shippingContactUpdate);
        })
            .catch(function (shippingContactUpdate) {
            _this.session.completeShippingContactSelection(shippingContactUpdate);
        });
    };
    /**
     * An event handler that is called when a new payment method is selected.
     * The onpaymentmethodselected function must resolve before the 30 second timeout
     * @param event - The event parameter contains the shippingMethod attribute.
     * @param onShippingMethodSelected - A promise that will complete the selection of a shipping method with an update.
     * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778009-onshippingcontactselected}
     */
    ApplePayService.prototype.onshippingmethodselected = function (event, onShippingMethodSelected) {
        var _this = this;
        return new Promise(function (resolve, reject) { return onShippingMethodSelected(resolve, reject, event); })
            .then(function (shippingMethodUpdate) {
            _this.session.completeShippingMethodSelection(shippingMethodUpdate);
        })
            .catch(function (shippingMethodUpdate) {
            _this.session.completeShippingMethodSelection(shippingMethodUpdate);
        });
    };
    /**
     * An event handler that is automatically called when the payment UI is dismissed.
     * This function can be called even after an onpaymentauthorized event has been dispatched.
     * @param event -
     * @param onCancel -
     * @see {@link https://developer.apple.com/documentation/apple_pay_on_the_web/applepaysession/1778029-oncancel}
     */
    ApplePayService.prototype.oncancel = function (event, onCancel) {
        return onCancel(event);
    };
    return ApplePayService;
}());
export default ApplePayService;
