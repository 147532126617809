import { __assign } from "tslib";
import logEvent from '../Services/log-event';
import postTelemetry from '../Services/post-telemetry';
import collectId from '../Services/collect-id';
import EventsQueue from './EventsQueue';
var Analytics = /** @class */ (function () {
    function Analytics(_a) {
        var _this = this;
        var loadingContext = _a.loadingContext, locale = _a.locale, originKey = _a.originKey, clientKey = _a.clientKey, analytics = _a.analytics;
        this.conversionId = null;
        this.queue = new EventsQueue();
        this.props = __assign(__assign({}, Analytics.defaultProps), analytics);
        var accessKey = clientKey || originKey;
        this.logEvent = logEvent({ loadingContext: loadingContext, locale: locale });
        this.logTelemetry = postTelemetry({ loadingContext: loadingContext, locale: locale, accessKey: accessKey });
        var _b = this.props, conversion = _b.conversion, enabled = _b.enabled;
        if (conversion && enabled) {
            if (this.props.conversionId) {
                this.conversionId = this.props.conversionId;
                this.queue.run(this.conversionId);
            }
            else {
                // If no conversionId is provided, fetch a new one
                collectId({ loadingContext: loadingContext, accessKey: accessKey })
                    .then(function (conversionId) {
                    _this.conversionId = conversionId;
                    _this.queue.run(_this.conversionId);
                })
                    .catch(function () {
                    _this.queue.run();
                });
            }
        }
    }
    Analytics.prototype.send = function (event) {
        var _this = this;
        var _a = this.props, conversion = _a.conversion, enabled = _a.enabled, telemetry = _a.telemetry;
        if (enabled === true) {
            if (telemetry === true) {
                var telemetryTask = function (conversionId) { return _this.logTelemetry(__assign(__assign({}, event), { conversionId: conversionId })).catch(function () { }); };
                this.queue.add(telemetryTask);
                // Not waiting for conversionId
                if (!conversion || this.conversionId) {
                    this.queue.run(this.conversionId);
                }
            }
            // Log pixel
            this.logEvent(event);
        }
    };
    Analytics.defaultProps = {
        enabled: true,
        telemetry: true,
        conversion: false,
        conversionId: null
    };
    return Analytics;
}());
export default Analytics;
