import { __assign } from "tslib";
import defaultRules from './defaultRules';
var Validator = /** @class */ (function () {
    function Validator(rules) {
        if (rules === void 0) { rules = {}; }
        this.rules = defaultRules;
        this.setRules(rules);
    }
    Validator.prototype.setRules = function (newRules) {
        this.rules = {
            input: __assign(__assign({}, (this.rules && this.rules.input)), (newRules && newRules.input)),
            blur: __assign(__assign({}, (this.rules && this.rules.blur)), (newRules && newRules.blur))
        };
    };
    Validator.prototype.validate = function (field, mode) {
        var _this = this;
        if (mode === void 0) { mode = 'blur'; }
        return function (value) {
            var fieldRule = _this.rules[mode][field] ? field : 'default';
            return _this.rules[mode][fieldRule](value);
        };
    };
    return Validator;
}());
export default Validator;
