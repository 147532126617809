var checkFormIsValid = function (pSecuredFields) {
    var securedFieldKeys = Object.keys(pSecuredFields);
    for (var i = 0, len = securedFieldKeys.length; i < len; i += 1) {
        var key = securedFieldKeys[i];
        if (!pSecuredFields[key].isValid) {
            return false;
        }
    }
    return true;
};
export function assessFormValidity() {
    var isValid = checkFormIsValid(this.state.securedFields);
    var validityHasChanged = isValid !== this.state.allValid;
    this.state.allValid = isValid;
    // Only call onAllValid callback if value has changed OR is true
    if (!isValid && !validityHasChanged)
        return;
    var callbackObj = { allValid: isValid, type: this.state.type, rootNode: this.props.rootNode };
    // BROADCAST VALID STATE OF THE FORM AS A WHOLE
    this.callbacks.onAllValid(callbackObj);
}
