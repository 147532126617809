import { ERROR_CODES, ERROR_MSG_UNSUPPORTED_CARD_ENTERED } from '../../../../../../core/Errors/constants';
export var processErrors = function (pFeedbackObj, securedField, type, rootNode, callbackFn) {
    if (!Object.prototype.hasOwnProperty.call(pFeedbackObj, 'error'))
        return null;
    var fieldType = pFeedbackObj.fieldType;
    var field = securedField;
    // Initialise error callback object
    var dataObj = { rootNode: rootNode, fieldType: fieldType, error: null, type: null };
    var isError = pFeedbackObj.error !== '';
    // Error is empty string && field is not already in error: do nothing - don't need to propagate this non-error if the field wasn't already in error
    // This situation arises when we encrypt a field and trigger an "error clearing" event
    // It also arises when an unsupportedCard (re. binLookup) is entered and the shopper continues to interact with the field (adding or deleting digits)
    if (!isError && !field.hasError) {
        return null;
    }
    // Ignore other errors whilst the field is in an "unsupportedCard" error state
    if (field.errorType === ERROR_CODES[ERROR_MSG_UNSUPPORTED_CARD_ENTERED]) {
        return null;
    }
    // Add props to error callback object
    dataObj.error = isError ? pFeedbackObj.error : '';
    dataObj.type = type;
    // Set error state & type on securedField instance
    field.hasError = isError;
    field.errorType = dataObj.error;
    callbackFn(dataObj);
    return dataObj;
};
