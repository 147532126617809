import { __assign, __extends } from "tslib";
import IssuerListContainer from '../helpers/IssuerListContainer';
var BillDeskWalletElement = /** @class */ (function (_super) {
    __extends(BillDeskWalletElement, _super);
    function BillDeskWalletElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BillDeskWalletElement.prototype.formatProps = function (props) {
        return __assign(__assign({}, _super.prototype.formatProps.call(this, props)), { showImage: false, placeholder: 'issuerList.wallet.placeholder' });
    };
    BillDeskWalletElement.type = 'billdesk_wallet';
    return BillDeskWalletElement;
}(IssuerListContainer));
export default BillDeskWalletElement;
