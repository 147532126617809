import { __extends } from "tslib";
import { Component, h } from 'preact';
var Iframe = /** @class */ (function (_super) {
    __extends(Iframe, _super);
    function Iframe() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Iframe.prototype.iframeOnLoad = function () {
        if (this.props.callback && typeof this.props.callback === 'function') {
            this.props.callback(this.iframeEl.contentWindow);
        }
    };
    Iframe.prototype.componentDidMount = function () {
        if (this.iframeEl.addEventListener) {
            this.iframeEl.addEventListener('load', this.iframeOnLoad.bind(this), false);
        }
        else if (this.iframeEl.attachEvent) {
            // IE fallback
            this.iframeEl.attachEvent('onload', this.iframeOnLoad.bind(this));
        }
        else {
            this.iframeEl.onload = this.iframeOnLoad.bind(this);
        }
    };
    Iframe.prototype.componentWillUnmount = function () {
        if (this.iframeEl.removeEventListener) {
            this.iframeEl.removeEventListener('load', this.iframeOnLoad.bind(this), false);
        }
        else if (this.iframeEl.detachEvent) {
            // IE fallback
            this.iframeEl.detachEvent('onload', this.iframeOnLoad.bind(this));
        }
        else {
            this.iframeEl.onload = null;
        }
    };
    Iframe.prototype.render = function (_a) {
        var _this = this;
        var name = _a.name, src = _a.src, width = _a.width, height = _a.height, minWidth = _a.minWidth, minHeight = _a.minHeight, border = _a.border, allow = _a.allow, title = _a.title;
        return (h("iframe", { ref: function (ref) {
                _this.iframeEl = ref;
            }, 
            /*
            // @ts-ignore */
            allow: allow, className: "adyen-checkout__iframe adyen-checkout__iframe--" + name, name: name, src: src, width: width, height: height, "min-width": minWidth, "min-heigth": minHeight, border: border, style: { border: 0 }, frameBorder: "0", title: title, referrerpolicy: "origin" }));
    };
    Iframe.defaultProps = {
        width: '0',
        height: '0',
        minWidth: '0',
        minHeight: '0',
        border: '0',
        src: null,
        allow: null,
        title: 'components iframe'
    };
    return Iframe;
}(Component));
export default Iframe;
