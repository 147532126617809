import { h } from 'preact';
import Img from '../../internal/Img';
export default function CampaignContent(_a) {
    var _b = _a.description, description = _b === void 0 ? '' : _b, _c = _a.name, name = _c === void 0 ? '' : _c, _d = _a.logoUrl, logoUrl = _d === void 0 ? '' : _d, _e = _a.url, url = _e === void 0 ? '' : _e, _f = _a.backgroundUrl, backgroundUrl = _f === void 0 ? '' : _f;
    var backgroundImage = "linear-gradient(0, #000, #0003), url(" + backgroundUrl + ")";
    return (h("div", { className: "adyen-checkout__campaign-container" },
        h(Img, { className: "adyen-checkout__campaign-background-image", style: { backgroundImage: backgroundImage }, backgroundUrl: backgroundUrl }),
        h("div", { className: "adyen-checkout__campaign-content" },
            logoUrl && h("img", { src: logoUrl, className: "adyen-checkout__campaign-logo", alt: name }),
            name && h("div", { className: "adyen-checkout__campaign-title" }, name),
            description && (h("div", { className: "adyen-checkout__campaign-description" },
                description,
                url && ' ›')))));
}
