import cx from 'classnames';
import './ButtonGroup.scss';
import { h } from 'preact';
var ButtonGroup = function (_a) {
    var _b = _a.options, options = _b === void 0 ? [] : _b, name = _a.name, onChange = _a.onChange;
    return (h("div", { className: "adyen-checkout__button-group" }, options.map(function (_a, index) {
        var label = _a.label, selected = _a.selected, value = _a.value, disabled = _a.disabled;
        return (h("label", { key: "" + name + index, className: cx({
                'adyen-checkout__button': true,
                'adyen-checkout__button--selected': selected,
                'adyen-checkout__button--disabled': disabled
            }) },
            h("input", { type: "radio", className: "adyen-checkout__button-group__input", value: value, checked: selected, onChange: onChange, disabled: disabled }),
            h("span", { className: "adyen-checkout__button-text" }, label)));
    })));
};
export default ButtonGroup;
