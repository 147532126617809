import { __extends, __spreadArrays } from "tslib";
import { Component, h } from 'preact';
import classNames from 'classnames';
import Spinner from '../Spinner';
import useCoreContext from '../../../core/Context/useCoreContext';
import './Button.scss';
var Button = /** @class */ (function (_super) {
    __extends(Button, _super);
    function Button() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClick = function (e) {
            e.preventDefault();
            if (!_this.props.disabled) {
                _this.props.onClick(e, { complete: _this.complete });
            }
        };
        _this.complete = function (delay) {
            if (delay === void 0) { delay = 1000; }
            _this.setState({ completed: true });
            setTimeout(function () {
                _this.setState({ completed: false });
            }, delay);
        };
        return _this;
    }
    Button.prototype.render = function (_a, _b) {
        var _c = _a.classNameModifiers, classNameModifiers = _c === void 0 ? [] : _c, disabled = _a.disabled, href = _a.href, icon = _a.icon, secondary = _a.secondary, inline = _a.inline, label = _a.label, status = _a.status;
        var completed = _b.completed;
        var i18n = useCoreContext().i18n;
        var buttonIcon = icon ? h("img", { className: "adyen-checkout__button__icon", src: icon, alt: "", "aria-hidden": "true" }) : '';
        var modifiers = __spreadArrays(classNameModifiers, (inline ? ['inline'] : []), (completed ? ['completed'] : []), (secondary ? ['secondary'] : []), (status === 'loading' || status === 'redirect' ? ['loading'] : []));
        var buttonClasses = classNames(__spreadArrays(['adyen-checkout__button'], modifiers.map(function (m) { return "adyen-checkout__button--" + m; })));
        var buttonStates = {
            loading: h(Spinner, { size: "medium" }),
            redirect: (h("span", { className: "adyen-checkout__button__content" },
                h(Spinner, { size: "small", inline: true }),
                i18n.get('payButton.redirecting'))),
            default: (h("span", { className: "adyen-checkout__button__content" },
                buttonIcon,
                h("span", { className: "adyen-checkout__button__text" }, label)))
        };
        var buttonText = buttonStates[status] || buttonStates.default;
        if (href) {
            return (h("a", { className: buttonClasses, href: href, disabled: disabled, target: this.props.target, rel: this.props.rel }, buttonText));
        }
        return (h("button", { className: buttonClasses, type: "button", disabled: disabled, onClick: this.onClick }, buttonText));
    };
    Button.defaultProps = {
        status: 'default',
        disabled: false,
        label: '',
        secondary: false,
        inline: false,
        target: '_self',
        onClick: function () { }
    };
    return Button;
}(Component));
export default Button;
