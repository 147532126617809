import { __assign } from "tslib";
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import classNames from 'classnames';
import useCoreContext from '../../../../core/Context/useCoreContext';
import Field from '../../../internal/FormFields/Field';
import { renderFormField } from '../../../internal/FormFields';
import { mbwayValidationRules } from './validate';
import Validator from '../../../../utils/Validator';
import './MBWayInput.scss';
function MBWayInput(props) {
    var i18n = useCoreContext().i18n;
    var validator = new Validator(mbwayValidationRules);
    var _a = useState(props.data), data = _a[0], setData = _a[1];
    var _b = useState({}), errors = _b[0], setErrors = _b[1];
    var _c = useState(__assign({}, (props.data.telephoneNumber && { telephoneNumber: validator.validate('telephoneNumber', 'input')(props.data.telephoneNumber).isValid }))), valid = _c[0], setValid = _c[1];
    var _d = useState('ready'), status = _d[0], setStatus = _d[1];
    this.setStatus = function (newStatus) {
        setStatus(newStatus);
    };
    this.showValidation = function () {
        var hasError = !validator.validate('telephoneNumber', 'input')(props.data.telephoneNumber).isValid;
        setErrors(__assign(__assign({}, errors), { telephoneNumber: hasError }));
    };
    var handleEventFor = function (key, mode) { return function (e) {
        var val = e.target.value;
        var _a = validator.validate('telephoneNumber', mode)(val), value = _a.value, isValid = _a.isValid, showError = _a.showError;
        setData(__assign(__assign({}, data), { telephoneNumber: value }));
        setErrors(__assign(__assign({}, errors), { telephoneNumber: !isValid && showError }));
        setValid(__assign(__assign({}, valid), { telephoneNumber: isValid }));
    }; };
    useEffect(function () {
        props.onChange({ data: data, isValid: valid.telephoneNumber });
    }, [data, valid]);
    return (h("div", { className: "adyen-checkout__mb-way" },
        h(Field, { errorMessage: !!errors.telephoneNumber && i18n.get('telephoneNumber.invalid'), label: i18n.get('telephoneNumber'), className: classNames('adyen-checkout__input--phone-number'), isValid: valid.telephoneNumber }, renderFormField('tel', {
            value: data.telephoneNumber,
            className: 'adyen-checkout__pm__phoneNumber__input',
            placeholder: props.placeholders.telephoneNumber,
            required: true,
            autoCorrect: 'off',
            onChange: handleEventFor('telephoneNumber', 'blur'),
            onInput: handleEventFor('telephoneNumber', 'input')
        })),
        props.showPayButton && props.payButton({ status: status, label: i18n.get('confirmPurchase') })));
}
MBWayInput.defaultProps = {
    onChange: function () { }
};
export default MBWayInput;
