import { __assign } from "tslib";
import { getComponent } from '../../../components';
var actionTypes = {
    redirect: function (action, props) {
        return getComponent('redirect', __assign(__assign(__assign({}, props), action), { statusType: 'redirect' }));
    },
    threeDS2Fingerprint: function (action, props) {
        return getComponent('threeDS2DeviceFingerprint', __assign(__assign({ createFromAction: props.createFromAction, fingerprintToken: action.token, paymentData: action.paymentData, onComplete: props.onAdditionalDetails, onError: props.onError, showSpinner: !props.isDropin, isDropin: !!props.isDropin }, props), { statusType: 'loading' }));
    },
    threeDS2Challenge: function (action, props) {
        return getComponent('threeDS2Challenge', __assign(__assign({}, props), { challengeToken: action.token, paymentData: action.paymentData, onComplete: props.onAdditionalDetails, onError: props.onError, size: '05', isDropin: !!props.isDropin, statusType: 'custom' }));
    },
    voucher: function (action, props) {
        return getComponent(action.paymentMethodType, __assign(__assign(__assign({}, action), props), { i18n: props.i18n, loadingContext: props.loadingContext, statusType: 'custom' }));
    },
    qrCode: function (action, props) {
        return getComponent(action.paymentMethodType, __assign(__assign(__assign({}, action), props), { onComplete: props.onAdditionalDetails, onError: props.onError, statusType: 'custom' }));
    },
    await: function (action, props) {
        return getComponent(action.paymentMethodType, __assign(__assign(__assign({}, action), props), { onComplete: props.onAdditionalDetails, onError: props.onError, statusType: 'custom' }));
    }
};
export default actionTypes;
