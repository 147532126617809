import { h } from 'preact';
import styles from '../../CardInput.module.scss';
import useCoreContext from '../../../../../../core/Context/useCoreContext';
import { getCardImageUrl } from '../../utils';
import './DualBrandingIcon.scss';
var DualBrandingIcon = function (_a) {
    var brand = _a.brand, onClick = _a.onClick, dataValue = _a.dataValue, notSelected = _a.notSelected;
    var loadingContext = useCoreContext().loadingContext;
    var imageName = brand === 'card' ? 'nocard' : brand;
    var handleError = function (e) {
        e.target.style.cssText = 'display: none';
    };
    return (h("img", { className: styles['card-input__icon'] + " " + (notSelected ? 'adyen-checkout__card__cardNumber__brandIcon--not-selected' : '') + " adyen-checkout__card__cardNumber__brandIcon", onError: handleError, alt: brand, src: getCardImageUrl(imageName, loadingContext), onClick: onClick, "data-value": dataValue }));
};
export default DualBrandingIcon;
