import * as logger from '../../../utilities/logger';
export var originCheckPassed = function (event, pLoadingContext, pShowWarnings) {
    var origin = event.origin; // || event.originalEvent.origin;
    var isCheckoutUrl = pLoadingContext.indexOf('/checkoutshopper/');
    var adyenDomain = isCheckoutUrl > -1 ? pLoadingContext.substring(0, isCheckoutUrl) : pLoadingContext;
    // Strip trailing /
    var lastCharIndex = adyenDomain.length - 1;
    if (adyenDomain.charAt(lastCharIndex) === '/') {
        adyenDomain = adyenDomain.substring(0, lastCharIndex);
    }
    if (origin !== adyenDomain) {
        if (pShowWarnings) {
            logger.warn('####################################################################################');
            logger.warn('WARNING postMessageValidation: postMessage listener for iframe::origin mismatch!\n Received message with origin:', origin, 'but the only allowed origin for messages to CSF is', adyenDomain);
            logger.warn('### event.data=', event.data);
            logger.warn('####################################################################################');
        }
        return false;
    }
    return true;
};
// Catch webpack postMessages responses
export var isWebpackPostMsg = function (event) {
    return event.data && event.data.type && typeof event.data.type === 'string' && event.data.type.indexOf('webpack') > -1;
};
// Catch webpack postMessages responses
export var isChromeVoxPostMsg = function (event) { return event.data && typeof event.data === 'string' && event.data.indexOf('cvox') > -1; };
