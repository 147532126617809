var select = function (root, selector) {
    var array = [];
    if (root) {
        // Convert NodeList to array
        if (typeof root.querySelectorAll === 'function') {
            array = [].slice.call(root.querySelectorAll(selector));
        }
    }
    return array;
};
var selectOne = function (root, selector) {
    if (!root) {
        return undefined;
    }
    return root.querySelector(selector);
};
var getAttribute = function (node, attribute) {
    if (!node) {
        return undefined;
    }
    return node.getAttribute(attribute);
};
var on = function (node, event, callback, useCapture) {
    if (typeof node.addEventListener === 'function') {
        node.addEventListener(event, callback, useCapture);
        return;
    }
    if (node.attachEvent) {
        node.attachEvent("on" + event, callback);
        return;
    }
    throw new Error(": Unable to bind " + event + "-event");
};
var off = function (node, event, callback, useCapture) {
    if (typeof node.addEventListener === 'function') {
        node.removeEventListener(event, callback, useCapture);
    }
    else if (node.attachEvent) {
        node.detachEvent("on" + event, callback);
    }
    else {
        throw new Error(": Unable to unbind " + event + "-event");
    }
};
/**
 * @internal
 * Removes all children elements of the given node.
 *
 * @param node -
 */
var removeAllChildren = function (node) {
    while (node.firstChild) {
        node.removeChild(node.firstChild);
    }
};
export { getAttribute, on, off, select, selectOne, removeAllChildren };
