import { __assign, __extends } from "tslib";
import QRLoaderContainer from '../helpers/QRLoaderContainer';
import { delay, countdownTime } from './config';
var WeChatPayElement = /** @class */ (function (_super) {
    __extends(WeChatPayElement, _super);
    function WeChatPayElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WeChatPayElement.prototype.formatProps = function (props) {
        return __assign({ delay: delay,
            countdownTime: countdownTime }, _super.prototype.formatProps.call(this, props));
    };
    WeChatPayElement.type = 'wechatpayQR';
    return WeChatPayElement;
}(QRLoaderContainer));
export default WeChatPayElement;
