import { __extends } from "tslib";
import RedirectElement from '../Redirect';
var VippsElement = /** @class */ (function (_super) {
    __extends(VippsElement, _super);
    function VippsElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VippsElement.type = 'vipps';
    VippsElement.defaultProps = {
        type: VippsElement.type,
        showPayButton: true,
        name: 'Vipps'
    };
    return VippsElement;
}(RedirectElement));
export default VippsElement;
