import { __extends } from "tslib";
import OpenInvoiceContainer from '../helpers/OpenInvoiceContainer';
var FacilyPay4x = /** @class */ (function (_super) {
    __extends(FacilyPay4x, _super);
    function FacilyPay4x() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FacilyPay4x.type = 'facilypay_4x';
    return FacilyPay4x;
}(OpenInvoiceContainer));
export default FacilyPay4x;
