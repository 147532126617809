import { __extends } from "tslib";
import IssuerListContainer from '../helpers/IssuerListContainer';
var IdealElement = /** @class */ (function (_super) {
    __extends(IdealElement, _super);
    function IdealElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IdealElement.type = 'ideal';
    return IdealElement;
}(IssuerListContainer));
export default IdealElement;
