import { __extends } from "tslib";
import { Component, h } from 'preact';
import classNames from 'classnames';
import PaymentMethodDetails from './PaymentMethodDetails';
import PaymentMethodIcon from './PaymentMethodIcon';
import DisableOneClickConfirmation from './DisableOneClickConfirmation';
import styles from '../DropinComponent.module.scss';
import './PaymentMethodItem.scss';
import useCoreContext from '../../../../core/Context/useCoreContext';
var PaymentMethodItem = /** @class */ (function (_super) {
    __extends(PaymentMethodItem, _super);
    function PaymentMethodItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showDisableStoredPaymentMethodConfirmation: false
        };
        _this.isMouseDown = false;
        _this.onFocus = function () {
            // Prevent a focus event when the user is clicking with a mouse.
            // TODO find a solution where we can remove this if-clause (and just call this.props.onSelect()) so that the screenreader reads the same "stored card ends in..." message for clicking on a PM as it does when tabbing between them
            if (!_this.isMouseDown) {
                _this.props.onSelect();
            }
        };
        _this.onMouseDown = function () {
            _this.isMouseDown = true;
        };
        _this.onMouseUp = function () {
            _this.isMouseDown = false;
        };
        _this.toggleDisableConfirmation = function () {
            _this.setState({ showDisableStoredPaymentMethodConfirmation: !_this.state.showDisableStoredPaymentMethodConfirmation });
        };
        _this.onDisableStoredPaymentMethod = function () {
            _this.props.onDisableStoredPaymentMethod(_this.props.paymentMethod);
            _this.toggleDisableConfirmation();
        };
        return _this;
    }
    PaymentMethodItem.prototype.componentDidMount = function () {
        var _this = this;
        this.props.paymentMethod.eventEmitter.on('brand', function (e) {
            _this.setState({ activeBrand: e.brand });
        });
    };
    PaymentMethodItem.prototype.componentWillUnmount = function () {
        var _this = this;
        this.props.paymentMethod.eventEmitter.off('brand', function (e) {
            _this.setState({ activeBrand: e.brand });
        });
    };
    PaymentMethodItem.prototype.render = function (_a, _b) {
        var _c;
        var paymentMethod = _a.paymentMethod, isSelected = _a.isSelected, isDisabling = _a.isDisabling, isLoaded = _a.isLoaded, isLoading = _a.isLoading, onSelect = _a.onSelect, standalone = _a.standalone;
        var _d = _b.activeBrand, activeBrand = _d === void 0 ? null : _d;
        var i18n = useCoreContext().i18n;
        if (!paymentMethod) {
            return null;
        }
        var paymentMethodClassnames = classNames((_c = {
                'adyen-checkout__payment-method': true
            },
            _c[styles['adyen-checkout__payment-method']] = true,
            _c["adyen-checkout__payment-method--" + paymentMethod.props.type] = true,
            _c['adyen-checkout__payment-method--selected'] = isSelected,
            _c[styles['adyen-checkout__payment-method--selected']] = isSelected,
            _c['adyen-checkout__payment-method--loading'] = isLoading,
            _c['adyen-checkout__payment-method--disabling'] = isDisabling,
            _c['adyen-checkout__payment-method--confirming'] = this.state.showDisableStoredPaymentMethodConfirmation,
            _c['adyen-checkout__payment-method--standalone'] = standalone,
            _c[styles['adyen-checkout__payment-method--loading']] = isLoading,
            _c[paymentMethod.props.id] = true,
            _c[this.props.className] = true,
            _c));
        var showRemovePaymentMethodButton = this.props.showRemovePaymentMethodButton && paymentMethod.props.oneClick && isSelected;
        var disableConfirmationId = "remove-" + paymentMethod.props.id;
        var showBrands = !paymentMethod.props.oneClick && paymentMethod.brands && paymentMethod.brands.length > 0;
        return (h("li", { key: paymentMethod.props.id, className: paymentMethodClassnames, onFocus: this.onFocus, onClick: onSelect, onMouseDown: this.onMouseDown, onMouseUp: this.onMouseUp, tabIndex: isLoading ? -1 : 0 },
            h("div", { className: "adyen-checkout__payment-method__header" },
                h("div", { className: "adyen-checkout__payment-method__header__title" },
                    h("span", { className: classNames({
                            'adyen-checkout__payment-method__radio': true,
                            'adyen-checkout__payment-method__radio--selected': isSelected
                        }), "aria-hidden": "true" }),
                    h(PaymentMethodIcon, { name: paymentMethod.props.name, src: paymentMethod.icon }),
                    h("span", { className: classNames({
                            'adyen-checkout__payment-method__name': true,
                            'adyen-checkout__payment-method__name--selected': isSelected
                        }), "aria-hidden": "true" }, paymentMethod.displayName)),
                showRemovePaymentMethodButton && (h("button", { type: "button", className: "adyen-checkout__button adyen-checkout__button--inline adyen-checkout__button--link", onClick: this.toggleDisableConfirmation, "aria-expanded": this.state.showDisableStoredPaymentMethodConfirmation, "aria-controls": disableConfirmationId }, i18n.get('storedPaymentMethod.disable.button'))),
                showBrands && (h("span", { className: "adyen-checkout__payment-method__brands" }, paymentMethod.brands.map(function (brand) { return (h(PaymentMethodIcon, { key: brand.name, name: brand.name, disabled: activeBrand && brand.name !== activeBrand, src: brand.icon })); })))),
            h("div", { className: "adyen-checkout__payment-method__details " + styles['adyen-checkout__payment-method__details'] },
                showRemovePaymentMethodButton && (h(DisableOneClickConfirmation, { id: disableConfirmationId, open: this.state.showDisableStoredPaymentMethodConfirmation, onDisable: this.onDisableStoredPaymentMethod, onCancel: this.toggleDisableConfirmation })),
                h(PaymentMethodDetails, { paymentMethodComponent: paymentMethod.render(), isLoaded: isLoaded }))));
    };
    PaymentMethodItem.defaultProps = {
        paymentMethod: null,
        isSelected: false,
        isLoaded: false,
        isLoading: false,
        showDisableStoredPaymentMethodConfirmation: false,
        onSelect: function () { }
    };
    return PaymentMethodItem;
}(Component));
export default PaymentMethodItem;
