import { h, Fragment } from 'preact';
import Fieldset from '../FormFields/Fieldset';
var ReadOnlyPersonalDetails = function (_a) {
    var data = _a.data;
    var firstName = data.firstName, lastName = data.lastName, shopperEmail = data.shopperEmail, telephoneNumber = data.telephoneNumber;
    return (h(Fieldset, { classNameModifiers: ['personalDetails'], label: "personalDetails", readonly: true },
        firstName && firstName + " ",
        lastName && lastName + " ",
        shopperEmail && (h(Fragment, null,
            h("br", null),
            shopperEmail)),
        telephoneNumber && (h(Fragment, null,
            h("br", null),
            telephoneNumber))));
};
export default ReadOnlyPersonalDetails;
