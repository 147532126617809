/**
 * Creates a script element from a certain source in the passed node selector.
 * If no selector is passed it will add the script element in the body.
 *
 * @example
 * ```
 * const script = new Script('https://example.com/script.js', '.container');
 * script.load().then(doSomething);
 *
 * // To clean up just call the remove method
 * script.remove();
 * ```
 */
var Script = /** @class */ (function () {
    function Script(src, node) {
        var _this = this;
        if (node === void 0) { node = 'body'; }
        this.load = function () {
            return new Promise(function (resolve, reject) {
                _this.script.src = _this.src;
                _this.script.async = true;
                _this.script.onload = resolve;
                _this.script.onerror = function () {
                    _this.remove();
                    reject(new Error("Unable to load script " + _this.src));
                };
                document.querySelector(_this.node).appendChild(_this.script);
            });
        };
        this.remove = function () { return _this.script.remove(); };
        this.script = document.createElement('script');
        this.src = src;
        this.node = node;
    }
    return Script;
}());
export default Script;
