import { __assign, __extends } from "tslib";
import IssuerListContainer from '../helpers/IssuerListContainer';
var EPSElement = /** @class */ (function (_super) {
    __extends(EPSElement, _super);
    function EPSElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EPSElement.prototype.formatProps = function (props) {
        return __assign(__assign({}, _super.prototype.formatProps.call(this, props)), { showImage: false });
    };
    EPSElement.type = 'eps';
    return EPSElement;
}(IssuerListContainer));
export default EPSElement;
