import { h } from 'preact';
import classNames from 'classnames';
import BrandIcon from './BrandIcon';
import DualBrandingIcon from './DualBrandingIcon/DualBrandingIcon';
import Field from '../../../../internal/FormFields/Field';
import useCoreContext from '../../../../../core/Context/useCoreContext';
import styles from '../CardInput.module.scss';
export default function CardNumber(props) {
    var _a;
    var i18n = useCoreContext().i18n;
    var _b = props.error, error = _b === void 0 ? '' : _b, _c = props.isValid, isValid = _c === void 0 ? false : _c, _d = props.onFocusField, onFocusField = _d === void 0 ? function () { } : _d, dualBrandingElements = props.dualBrandingElements, dualBrandingChangeHandler = props.dualBrandingChangeHandler, dualBrandingSelected = props.dualBrandingSelected;
    return (h(Field, { label: props.label, focused: props.focused, filled: props.filled, classNameModifiers: ['cardNumber'], onFocusField: function () { return onFocusField('encryptedCardNumber'); }, errorMessage: error && i18n.get(error), isValid: isValid, dualBrandingElements: dualBrandingElements },
        h("span", { "data-cse": "encryptedCardNumber", className: classNames((_a = {
                    'adyen-checkout__input': true,
                    'adyen-checkout__input--large': true,
                    'adyen-checkout__card__cardNumber__input': true
                },
                _a[styles['adyen-checkout__input']] = true,
                _a['adyen-checkout__input--error'] = error,
                _a['adyen-checkout__input--focus'] = props.focused,
                _a['adyen-checkout__input--valid'] = isValid,
                _a['adyen-checkout__card__cardNumber__input--noBrand'] = !props.showBrandIcon,
                _a)) }, props.showBrandIcon && !dualBrandingElements && h(BrandIcon, { brand: props.brand })),
        dualBrandingElements && !error && (h("div", { className: classNames([
                'adyen-checkout__card__dual-branding__buttons',
                { 'adyen-checkout__card__dual-branding__buttons--active': isValid }
            ]) }, dualBrandingElements.map(function (element) { return (h(DualBrandingIcon, { key: element.id, brand: element.id, onClick: dualBrandingChangeHandler, dataValue: element.id, notSelected: dualBrandingSelected !== '' && dualBrandingSelected !== element.id })); })))));
}
