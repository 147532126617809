import { __rest } from "tslib";
import { h } from 'preact';
import classNames from 'classnames';
import Button from '../Button';
import { copyToClipboard } from '../../../utils/clipboard';
import getImage from '../../../utils/get-image';
import useCoreContext from '../../../core/Context/useCoreContext';
import './Voucher.scss';
export default function Voucher(_a) {
    var _b = _a.voucherDetails, voucherDetails = _b === void 0 ? [] : _b, _c = _a.className, className = _c === void 0 ? '' : _c, props = __rest(_a, ["voucherDetails", "className"]);
    var _d = useCoreContext(), i18n = _d.i18n, loadingContext = _d.loadingContext;
    return (h("div", { className: classNames('adyen-checkout__voucher-result', "adyen-checkout__voucher-result--" + props.paymentMethodType, className) },
        h("div", { className: "adyen-checkout__voucher-result__top" },
            h("div", { className: "adyen-checkout__voucher-result__image" },
                !!props.imageUrl && (h("span", { className: "adyen-checkout__voucher-result__image__wrapper" },
                    h("img", { alt: props.paymentMethodType, className: "adyen-checkout__voucher-result__image__brand", src: props.imageUrl }))),
                !!props.issuerImageUrl && (h("span", { className: "adyen-checkout__voucher-result__image__wrapper" },
                    h("img", { alt: props.paymentMethodType, className: "adyen-checkout__voucher-result__image__issuer", src: props.issuerImageUrl })))),
            h("div", { className: "adyen-checkout__voucher-result__introduction" },
                props.introduction,
                ' ',
                props.instructionsUrl && (h("a", { className: "adyen-checkout__link adyen-checkout__link--voucher-result-instructions", href: props.instructionsUrl, target: "_blank", rel: "noopener noreferrer" },
                    i18n.get('voucher.readInstructions'),
                    " \u203A"))),
            props.amount && (h("div", { className: "adyen-checkout__voucher-result__amount" },
                props.amount,
                props.surcharge && (h("span", { className: "adyen-checkout__voucher-result__surcharge" },
                    "(",
                    i18n.get('voucher.surcharge').replace('%@', props.surcharge),
                    ")"))))),
        h("div", { className: "adyen-checkout__voucher-result__separator" },
            h("div", { className: "adyen-checkout__voucher-result__separator__inner" }),
            h("div", { className: "adyen-checkout__voucher-result__code__label" },
                h("span", { className: "adyen-checkout__voucher-result__code__label__text" }, i18n.get('voucher.paymentReferenceLabel')))),
        h("div", { className: "adyen-checkout__voucher-result__bottom" },
            props.reference && (h("div", { className: "adyen-checkout__voucher-result__code" },
                props.barcode && (h("img", { alt: i18n.get('voucher.paymentReferenceLabel'), className: "adyen-checkout__voucher-result__code__barcode", src: props.barcode })),
                h("span", null, props.reference))),
            (!!props.downloadUrl || !!props.copyBtn) && (h("ul", { className: "adyen-checkout__voucher-result__actions" },
                !!props.copyBtn && (h("li", { className: "adyen-checkout__voucher-result__actions__item" },
                    h(Button, { inline: true, secondary: true, onClick: function (e, _a) {
                            var complete = _a.complete;
                            copyToClipboard(props.reference);
                            complete();
                        }, icon: getImage({ loadingContext: loadingContext, imageFolder: 'components/' })('copy'), label: i18n.get('button.copy') }))),
                !!props.downloadUrl && (h("li", { className: "adyen-checkout__voucher-result__actions__item" },
                    h(Button, { inline: true, secondary: true, href: props.downloadUrl, icon: getImage({ loadingContext: loadingContext, imageFolder: 'components/' })('download'), label: i18n.get('button.download'), target: "_blank", rel: "noopener noreferrer" }))))),
            h("ul", { className: "adyen-checkout__voucher-result__details" }, voucherDetails
                .filter(function (_a) {
                var label = _a.label, value = _a.value;
                return !!label && !!value;
            })
                .map(function (_a, index) {
                var label = _a.label, value = _a.value;
                return (h("li", { key: index, className: "adyen-checkout__voucher-result__details__item" },
                    h("span", { className: "adyen-checkout__voucher-result__details__label" }, label),
                    h("span", { className: "adyen-checkout__voucher-result__details__value" }, value)));
            })))));
}
