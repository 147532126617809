import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import defaultProps from './defaultProps';
import DropinComponent from '../../components/Dropin/components/DropinComponent';
import CoreProvider from '../../core/Context/CoreProvider';
import { getComponentConfiguration } from '../index';
var DropinElement = /** @class */ (function (_super) {
    __extends(DropinElement, _super);
    function DropinElement(props) {
        var _this = _super.call(this, props) || this;
        _this.dropinRef = null;
        _this.handleSubmit = function () {
            var _a = _this.activePaymentMethod, data = _a.data, isValid = _a.isValid;
            if (!isValid) {
                _this.showValidation();
                return false;
            }
            return _this.props.onSubmit({ data: data, isValid: isValid }, _this);
        };
        _this.submit = _this.submit.bind(_this);
        return _this;
    }
    Object.defineProperty(DropinElement.prototype, "isValid", {
        get: function () {
            return !!this.dropinRef && !!this.dropinRef.state.activePaymentMethod && !!this.dropinRef.state.activePaymentMethod.isValid;
        },
        enumerable: false,
        configurable: true
    });
    DropinElement.prototype.showValidation = function () {
        if (this.dropinRef.state.activePaymentMethod) {
            this.dropinRef.state.activePaymentMethod.showValidation();
        }
        return this;
    };
    DropinElement.prototype.setStatus = function (status, props) {
        if (props === void 0) { props = {}; }
        this.dropinRef.setStatus({ type: status, props: props });
        return this;
    };
    Object.defineProperty(DropinElement.prototype, "activePaymentMethod", {
        get: function () {
            if (!this.dropinRef.state && !this.dropinRef.state.activePaymentMethod) {
                return null;
            }
            return this.dropinRef.state.activePaymentMethod;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DropinElement.prototype, "data", {
        get: function () {
            if (!this.activePaymentMethod) {
                return null;
            }
            return this.dropinRef.state.activePaymentMethod.data;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Calls the onSubmit event with the state of the activePaymentMethod
     */
    DropinElement.prototype.submit = function () {
        var _this = this;
        if (!this.activePaymentMethod) {
            throw new Error('No active payment method.');
        }
        this.activePaymentMethod
            .startPayment()
            .then(this.handleSubmit)
            .catch(function (error) { return _this.props.onError(error); });
    };
    DropinElement.prototype.handleAction = function (action) {
        var _this = this;
        if (!action || !action.type)
            throw new Error('Invalid Action');
        if (this.activePaymentMethod.updateWithAction) {
            return this.activePaymentMethod.updateWithAction(action);
        }
        // Extract desired props that we need to pass on from the pmConfiguration for this particular PM
        var pmConfig = getComponentConfiguration(action.paymentMethodType, this.props.paymentMethodsConfiguration);
        var paymentAction = this.props.createFromAction(action, __assign({ isDropin: true, onAdditionalDetails: function (state) { return _this.props.onAdditionalDetails(state, _this); }, onError: this.props.onError }, ((pmConfig === null || pmConfig === void 0 ? void 0 : pmConfig.onError) && { onError: pmConfig.onError }) // Overwrite ref to onError in case the merchant has defined one in the pmConfig options
        ));
        if (paymentAction) {
            return this.setStatus(paymentAction.props.statusType, { component: paymentAction });
        }
        return null;
    };
    DropinElement.prototype.closeActivePaymentMethod = function () {
        this.dropinRef.closeActivePaymentMethod();
    };
    DropinElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
            h(DropinComponent, __assign({}, this.props, { onChange: this.setState, onSubmit: this.handleSubmit, elementRef: this.elementRef, ref: function (dropinRef) {
                    _this.dropinRef = dropinRef;
                } }))));
    };
    DropinElement.type = 'dropin';
    DropinElement.defaultProps = defaultProps;
    return DropinElement;
}(UIElement));
export default DropinElement;
