import { __extends } from "tslib";
import IssuerListContainer from '../helpers/IssuerListContainer';
var MolPayEbankingVNElement = /** @class */ (function (_super) {
    __extends(MolPayEbankingVNElement, _super);
    function MolPayEbankingVNElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MolPayEbankingVNElement.type = 'molpay_ebanking_VN';
    return MolPayEbankingVNElement;
}(IssuerListContainer));
export default MolPayEbankingVNElement;
