import { __assign, __rest, __spreadArrays } from "tslib";
import { h } from 'preact';
import Button from '../Button';
import useCoreContext from '../../../core/Context/useCoreContext';
var PayButton = function (_a) {
    var amount = _a.amount, _b = _a.classNameModifiers, classNameModifiers = _b === void 0 ? [] : _b, label = _a.label, props = __rest(_a, ["amount", "classNameModifiers", "label"]);
    var i18n = useCoreContext().i18n;
    var isZeroAuth = amount && {}.hasOwnProperty.call(amount, 'value') && amount.value === 0;
    var defaultLabel = isZeroAuth
        ? i18n.get('confirmPreauthorization')
        : i18n.get('payButton') + " " + (!!(amount === null || amount === void 0 ? void 0 : amount.value) && !!(amount === null || amount === void 0 ? void 0 : amount.currency) ? i18n.amount(amount.value, amount.currency) : '');
    return h(Button, __assign({}, props, { classNameModifiers: __spreadArrays(classNameModifiers, ['pay']), label: label || defaultLabel }));
};
export default PayButton;
