import { __assign } from "tslib";
import { ADYEN_CLIENTID_TEST, ADYEN_CLIENTID_LIVE, INTEGRATION_DATE, PAYPAL_JS_URL, SUPPORTED_LOCALES } from './config';
/**
 * Returns either a locale supported by PayPal or null, in order to let the PayPal SDK auto-detect the shopper locale.
 */
var getSupportedLocale = function (locale) {
    var formattedLocale = locale ? locale.replace('-', '_') : null;
    var supportedLocale = SUPPORTED_LOCALES.includes(formattedLocale) ? formattedLocale : null;
    return supportedLocale;
};
/**
 * Returns an object of settings for the PayPal SDK
 */
var getPaypalSettings = function (_a) {
    var amount = _a.amount, countryCode = _a.countryCode, debug = _a.debug, _b = _a.environment, environment = _b === void 0 ? '' : _b, locale = _a.locale, configuration = _a.configuration;
    var shopperLocale = getSupportedLocale(locale);
    var currency = amount ? amount.currency : null;
    var isTestEnvironment = environment.toLowerCase() === 'test';
    var clientId = isTestEnvironment ? ADYEN_CLIENTID_TEST : ADYEN_CLIENTID_LIVE;
    var merchantId = configuration.merchantId, intent = configuration.intent;
    return __assign(__assign(__assign(__assign(__assign(__assign(__assign({}, (merchantId && { 'merchant-id': merchantId })), (shopperLocale && { locale: shopperLocale })), (countryCode && isTestEnvironment && { 'buyer-country': countryCode })), (debug && isTestEnvironment && { debug: debug })), (currency && { currency: currency })), (intent && { intent: intent })), { 'client-id': clientId, 'integration-date': INTEGRATION_DATE, components: 'buttons,funding-eligibility' });
};
/**
 * Returns the PayPal SDK script URL with query parameters
 * @see {@link https://developer.paypal.com/docs/checkout/reference/customize-sdk/}
 */
var getPaypalUrl = function (props) {
    var settings = getPaypalSettings(props);
    var params = decodeURIComponent(Object.keys(settings)
        .map(function (key) { return key + "=" + settings[key]; })
        .join('&'));
    return PAYPAL_JS_URL + "?" + params;
};
export { getPaypalSettings, getPaypalUrl };
