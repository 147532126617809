import { __rest } from "tslib";
import { getDecimalAmount } from '../../utils/amount-util';
import config from './config';
/**
 * Configure your site's support for payment methods supported by the Google Pay API.
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#IsReadyToPayRequest|isReadyToPayRequest}
 * @returns Google Pay API version, payment methods supported by the site
 */
export function isReadyToPayRequest(_a) {
    var allowedAuthMethods = _a.allowedAuthMethods, allowedCardNetworks = _a.allowedCardNetworks, _b = _a.existingPaymentMethodRequired, existingPaymentMethodRequired = _b === void 0 ? false : _b;
    return {
        apiVersion: config.API_VERSION,
        apiVersionMinor: config.API_VERSION_MINOR,
        allowedPaymentMethods: [
            {
                type: 'CARD',
                parameters: {
                    allowedAuthMethods: allowedAuthMethods,
                    allowedCardNetworks: allowedCardNetworks
                },
                tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {}
                }
            }
        ],
        existingPaymentMethodRequired: existingPaymentMethodRequired
    };
}
/**
 * Provide Google Pay API with a payment amount, currency, and amount status
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/object#TransactionInfo|TransactionInfo}
 * @returns transaction info, suitable for use as transactionInfo property of PaymentDataRequest
 */
export function getTransactionInfo(currencyCode, totalPrice, totalPriceStatus, countryCode) {
    if (currencyCode === void 0) { currencyCode = 'USD'; }
    if (totalPrice === void 0) { totalPrice = 0; }
    if (totalPriceStatus === void 0) { totalPriceStatus = 'FINAL'; }
    if (countryCode === void 0) { countryCode = 'US'; }
    var formattedPrice = String(getDecimalAmount(totalPrice, currencyCode));
    return {
        countryCode: countryCode,
        currencyCode: currencyCode,
        totalPrice: formattedPrice,
        totalPriceStatus: totalPriceStatus // Price will not change
    };
}
export function initiatePaymentRequest(_a) {
    var configuration = _a.configuration, props = __rest(_a, ["configuration"]);
    return {
        apiVersion: config.API_VERSION,
        apiVersionMinor: config.API_VERSION_MINOR,
        transactionInfo: getTransactionInfo(props.amount.currency, props.amount.value, props.totalPriceStatus, props.countryCode),
        merchantInfo: {
            merchantId: configuration.merchantId,
            merchantName: configuration.merchantName
        },
        allowedPaymentMethods: [
            {
                type: 'CARD',
                tokenizationSpecification: {
                    type: 'PAYMENT_GATEWAY',
                    parameters: {
                        gateway: config.GATEWAY,
                        gatewayMerchantId: configuration.gatewayMerchantId
                    }
                },
                parameters: {
                    allowedAuthMethods: props.allowedAuthMethods,
                    allowedCardNetworks: props.allowedCardNetworks,
                    allowPrepaidCards: props.allowPrepaidCards,
                    allowCreditCards: props.allowCreditCards,
                    billingAddressRequired: props.billingAddressRequired,
                    billingAddressParameters: props.billingAddressParameters
                }
            }
        ],
        emailRequired: props.emailRequired,
        shippingAddressRequired: props.shippingAddressRequired,
        shippingAddressParameters: props.shippingAddressParameters,
        shippingOptionRequired: props.shippingOptionRequired,
        shippingOptionParameters: props.shippingOptionParameters,
        callbackIntents: props.callbackIntents
    };
}
