import { __assign } from "tslib";
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { renderFormField } from '../../../../../internal/FormFields';
import Field from '../../../../../internal/FormFields/Field';
import useCoreContext from '../../../../../../core/Context/useCoreContext';
import Fieldset from '../../../../../internal/FormFields/Fieldset/Fieldset';
import RadioGroup from '../../../../../internal/FormFields/RadioGroup';
import styles from '../../CardInput.module.scss';
/**
 * Installments generic dropdown
 */
function Installments(props) {
    var _a;
    var i18n = useCoreContext().i18n;
    var amount = props.amount, brand = props.brand, onChange = props.onChange, type = props.type;
    var _b = useState(1), installmentAmount = _b[0], setInstallmentAmount = _b[1];
    var _c = useState('onetime'), radioBtnValue = _c[0], setRadioBtnValue = _c[1];
    var installmentOptions = props.installmentOptions[brand] || props.installmentOptions.card;
    // hasRadioButtonUI determines if we have 3 radio buttons in the UI ('onetime', 'installments' and 'revolving')
    var hasRadioButtonUI = (_a = installmentOptions === null || installmentOptions === void 0 ? void 0 : installmentOptions.plans) === null || _a === void 0 ? void 0 : _a.includes('revolving');
    var getPartialAmount = function (divider) { return i18n.amount(amount.value / divider, amount.currency); };
    var onSelectInstallment = function (e) {
        var selectedInstallments = e.currentTarget.getAttribute('data-value');
        setInstallmentAmount(Number(selectedInstallments));
    };
    var onRadioSelect = function (e) {
        var selectedBtn = e.currentTarget.getAttribute('value');
        setRadioBtnValue(selectedBtn);
    };
    var installmentItemsMapper = function (value) {
        var translationKey;
        var translationObj;
        if (type === 'amount') {
            translationKey = 'installmentOption';
            translationObj = { count: value, values: { times: value, partialValue: getPartialAmount(value) } };
        }
        else {
            translationKey = "installmentOptionMonths";
            translationObj = { count: value, values: { times: value } };
        }
        return {
            id: value,
            name: amount.value ? i18n.get(translationKey, translationObj) : "" + value
        };
    };
    useEffect(function () {
        var newAmount = installmentOptions && installmentOptions.values.includes(installmentAmount) ? installmentAmount : 1;
        setInstallmentAmount(newAmount);
    }, [brand]);
    useEffect(function () {
        var stateObj = __assign(__assign({ value: installmentAmount }, (hasRadioButtonUI && radioBtnValue === 'revolving' && { plan: radioBtnValue, value: 1 })), (hasRadioButtonUI && radioBtnValue === 'onetime' && { value: 1 }));
        onChange(installmentOptions ? stateObj : { value: null });
    }, [installmentAmount, installmentOptions, radioBtnValue]);
    if (!installmentOptions)
        return null;
    if (amount.value === 0)
        return null;
    // Alternate interface for installments with the possibility of a "revolving" plan
    if (hasRadioButtonUI) {
        return (h("div", { className: "adyen-checkout__installments" },
            h(Fieldset, { classNameModifiers: ['revolving-plan'], label: '' },
                h(RadioGroup, { items: [
                        { id: 'onetime', name: 'installments.oneTime' },
                        { id: 'installments', name: 'installments.installments' },
                        { id: 'revolving', name: 'installments.revolving' }
                    ], i18n: i18n, onChange: onRadioSelect, value: radioBtnValue }),
                h(Field, { className: radioBtnValue !== 'installments'
                        ? "" + styles['revolving-plan-installments__disabled']
                        : "" + styles['revolving-plan-installments'], classNameModifiers: ['revolving-plan-installments'] }, renderFormField('select', {
                    items: installmentOptions.values.map(installmentItemsMapper),
                    selected: installmentAmount,
                    onChange: onSelectInstallment,
                    name: 'installments'
                })))));
    }
    return (h("div", { className: "adyen-checkout__installments" },
        h(Field, { label: i18n.get('installments'), classNameModifiers: ['installments'] }, renderFormField('select', {
            items: installmentOptions.values.map(installmentItemsMapper),
            selected: installmentAmount,
            onChange: onSelectInstallment,
            name: 'installments'
        }))));
}
Installments.defaultProps = {
    brand: '',
    amount: {},
    onChange: function () { }
};
export default Installments;
