import { selectOne, select } from '../utilities/dom';
var getPreviousTabbableEl = function (matchEl, getPrevious) {
    if (getPrevious === void 0) { getPrevious = true; }
    var selStr = '*[data-cse], a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), ' +
        'button:not([disabled]), object, embed, *[tabindex], *[contenteditable]';
    var allPotentialTabEls = Array.prototype.slice.call(select(document, selStr));
    var actualTabEls = [];
    allPotentialTabEls.forEach(function (potentialTabEl) {
        var tabIndex = potentialTabEl.getAttribute('tabindex');
        var isValidTabEl = !tabIndex || parseInt(tabIndex, 10) >= 0; // If tabindex hasn't been set, or is set and is >= 0
        var bounds = potentialTabEl.getBoundingClientRect();
        var hasDimensions = bounds.width > 0 && bounds.height > 0;
        if (isValidTabEl && hasDimensions) {
            actualTabEls.push(potentialTabEl);
        }
    });
    // Loop through an array until it finds an element based on a test fn, and then return the elements index
    var getIndexThruTest = function (arr, testFn) {
        for (var n = 0; n < arr.length; n += 1) {
            if (testFn(arr[n])) {
                return n;
            }
        }
        return -1;
    };
    // Test function
    var isElOrContainsEl = function (tabEl) { return tabEl === matchEl || matchEl.contains(tabEl); };
    // Loop through the actualTabEls array until it finds the matchEl, returning its index
    var matchElIndex = getIndexThruTest(actualTabEls, isElOrContainsEl);
    // Are we actually getting the previous element OR the next?
    var indexModifier = getPrevious ? -1 : 1;
    return actualTabEls[matchElIndex + indexModifier];
};
export var findRootNode = function (pRootNode) {
    var rootNode;
    // Expect to be sent the actual html node...
    if (typeof pRootNode === 'object') {
        rootNode = pRootNode;
    }
    if (typeof pRootNode === 'string') {
        // ... but if only sent a string - find it ourselves
        rootNode = selectOne(document, pRootNode);
        if (!rootNode) {
            return null;
        }
    }
    return rootNode;
};
export var getPreviousTabbableNonSFElement = function (passedFieldType, rootNode) {
    // --
    var sfEl = selectOne(rootNode, "[data-cse=" + passedFieldType + "]");
    return getPreviousTabbableEl(sfEl);
};
