import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import MultibancoVoucherResult from './components/MultibancoVoucherResult';
import CoreProvider from '../../core/Context/CoreProvider';
import RedirectButton from '../internal/RedirectButton';
var MultibancoElement = /** @class */ (function (_super) {
    __extends(MultibancoElement, _super);
    function MultibancoElement() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.handleRef = function (ref) {
            _this.componentRef = ref;
        };
        return _this;
    }
    Object.defineProperty(MultibancoElement.prototype, "isValid", {
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    MultibancoElement.prototype.formatProps = function (props) {
        return __assign(__assign({}, props), { name: props.name || 'Multibanco' });
    };
    /**
     * Formats the component data output
     */
    MultibancoElement.prototype.formatData = function () {
        return {
            paymentMethod: {
                type: this.props.type || MultibancoElement.type
            }
        };
    };
    MultibancoElement.prototype.render = function () {
        var _this = this;
        if (this.props.reference) {
            return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
                h(MultibancoVoucherResult, __assign({ ref: this.handleRef }, this.props))));
        }
        if (this.props.showPayButton) {
            return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
                h(RedirectButton, { name: this.displayName, amount: this.props.amount, payButton: this.payButton, onSubmit: this.submit, ref: function (ref) {
                        _this.componentRef = ref;
                    } })));
        }
        return null;
    };
    MultibancoElement.type = 'multibanco';
    MultibancoElement.defaultProps = { showPayButton: true };
    return MultibancoElement;
}(UIElement));
export { MultibancoElement };
export default MultibancoElement;
