import { __extends } from "tslib";
import { Component, h, toChildArray } from 'preact';
import { CoreContext } from './CoreContext';
/**
 * CoreProvider Component
 * Wraps a component delaying the render until after the i18n module is fully loaded
 */
var CoreProvider = /** @class */ (function (_super) {
    __extends(CoreProvider, _super);
    function CoreProvider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CoreProvider.prototype.render = function (_a) {
        var children = _a.children;
        return (h(CoreContext.Provider, { value: { i18n: this.props.i18n, loadingContext: this.props.loadingContext } }, toChildArray(children)));
    };
    return CoreProvider;
}(Component));
export default CoreProvider;
