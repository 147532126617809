import { __assign, __extends } from "tslib";
import OpenInvoiceContainer from '../helpers/OpenInvoiceContainer';
var ALLOWED_COUNTRIES = ['CA', 'US'];
var Affirm = /** @class */ (function (_super) {
    __extends(Affirm, _super);
    function Affirm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Affirm.prototype.formatProps = function (props) {
        return __assign(__assign({}, _super.prototype.formatProps.call(this, props)), { allowedCountries: ALLOWED_COUNTRIES, personalDetailsRequiredFields: ['firstName', 'lastName', 'telephoneNumber', 'shopperEmail'] });
    };
    Affirm.type = 'affirm';
    return Affirm;
}(OpenInvoiceContainer));
export default Affirm;
