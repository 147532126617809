import { __assign, __extends } from "tslib";
import QRLoaderContainer from '../helpers/QRLoaderContainer';
import { STATUS_INTERVAL, COUNTDOWN_MINUTES } from './config';
var BCMCMobileElement = /** @class */ (function (_super) {
    __extends(BCMCMobileElement, _super);
    function BCMCMobileElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BCMCMobileElement.prototype.formatProps = function (props) {
        var isMobile = window.matchMedia('(max-width: 768px)').matches && /Android|iPhone|iPod/.test(navigator.userAgent);
        return __assign({ delay: STATUS_INTERVAL, countdownTime: COUNTDOWN_MINUTES, shouldRedirectOnMobile: true, buttonLabel: isMobile ? 'openApp' : 'generateQRCode' }, _super.prototype.formatProps.call(this, props));
    };
    BCMCMobileElement.type = 'bcmc_mobile';
    return BCMCMobileElement;
}(QRLoaderContainer));
export default BCMCMobileElement;
