import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import SecuredFields from '../internal/SecuredFields';
import CoreProvider from '../../core/Context/CoreProvider';
import collectBrowserInfo from '../../utils/browserInfo';
import getImage from '../../utils/get-image';
var SecuredFieldsElement = /** @class */ (function (_super) {
    __extends(SecuredFieldsElement, _super);
    function SecuredFieldsElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SecuredFieldsElement.prototype.formatProps = function (props) {
        return __assign(__assign(__assign({}, props), { type: props.type === 'scheme' ? 'card' : props.type }), (props.brands && !props.groupTypes && { groupTypes: props.brands }));
    };
    /**
     * Formats the component data output
     */
    SecuredFieldsElement.prototype.formatData = function () {
        return {
            paymentMethod: __assign({ type: SecuredFieldsElement.type }, this.state.data),
            browserInfo: this.browserInfo
        };
    };
    SecuredFieldsElement.prototype.updateStyles = function (stylesObj) {
        var _a;
        if ((_a = this.componentRef) === null || _a === void 0 ? void 0 : _a.updateStyles)
            this.componentRef.updateStyles(stylesObj);
        return this;
    };
    SecuredFieldsElement.prototype.setFocusOn = function (frame) {
        var _a;
        if ((_a = this.componentRef) === null || _a === void 0 ? void 0 : _a.setFocusOn)
            this.componentRef.setFocusOn(frame);
        return this;
    };
    SecuredFieldsElement.prototype.processBinLookupResponse = function (binLookupObject) {
        var _a;
        if ((_a = this.componentRef) === null || _a === void 0 ? void 0 : _a.processBinLookupResponse)
            this.componentRef.processBinLookupResponse(binLookupObject);
        return this;
    };
    Object.defineProperty(SecuredFieldsElement.prototype, "isValid", {
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SecuredFieldsElement.prototype, "icon", {
        get: function () {
            return getImage({ loadingContext: this.props.loadingContext })(this.props.type);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SecuredFieldsElement.prototype, "browserInfo", {
        get: function () {
            return collectBrowserInfo();
        },
        enumerable: false,
        configurable: true
    });
    SecuredFieldsElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
            h(SecuredFields, __assign({ ref: function (ref) {
                    _this.componentRef = ref;
                } }, this.props, this.state, { rootNode: this._node, onChange: this.setState }))));
    };
    SecuredFieldsElement.type = 'scheme';
    return SecuredFieldsElement;
}(UIElement));
export { SecuredFieldsElement };
export default SecuredFieldsElement;
