import { __assign, __extends } from "tslib";
import IssuerListContainer from '../helpers/IssuerListContainer';
var PayuNetBankingElement = /** @class */ (function (_super) {
    __extends(PayuNetBankingElement, _super);
    function PayuNetBankingElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PayuNetBankingElement.prototype.formatProps = function (props) {
        return __assign(__assign({}, _super.prototype.formatProps.call(this, props)), { showImage: false });
    };
    PayuNetBankingElement.type = 'payu_IN_nb';
    return PayuNetBankingElement;
}(IssuerListContainer));
export default PayuNetBankingElement;
