import { ENCRYPTED_EXPIRY_DATE } from '../../configuration/constants';
// import * as logger from '../../utilities/logger';
var makeCallbackObj = function (pFieldType, pEncryptedFieldName, uuid, pIsValid, pTxVariant, pRootNode) { return ({
    fieldType: pFieldType,
    encryptedFieldName: pEncryptedFieldName,
    uid: uuid,
    valid: pIsValid,
    type: pTxVariant,
    rootNode: pRootNode // A ref to the 'form' element holding the securedFields
}); };
export var makeCallbackObjectsValidation = function (pFieldType, pTxVariant, pRootNode) {
    // - create callback objects to report the changed valid state of the field
    var isExpiryDateField = pFieldType === ENCRYPTED_EXPIRY_DATE;
    var callbackObjectsArr = [];
    var sepExpiryDateNames = ['encryptedExpiryMonth', 'encryptedExpiryYear'];
    var i;
    var uuid;
    var encryptedType;
    var encryptedFieldName;
    // For expiryDate field we need to remove 2 DOM elements & create 2 objects (relating to month & year)
    // - for everything else we just need to remove 1 element & create 1 callback object
    var totalFields = isExpiryDateField ? 2 : 1;
    for (i = 0; i < totalFields; i += 1) {
        encryptedType = isExpiryDateField ? sepExpiryDateNames[i] : pFieldType; // encryptedCardNumber, encryptedSecurityCode, encryptedExpiryMonth, encryptedExpiryYear
        uuid = pTxVariant + "-encrypted-" + encryptedType; // card-encrypted-encryptedCardNumber, card-encrypted-encryptedSecurityCode, card-encrypted-encryptedExpiryMonth, card-encrypted-encryptedExpiryYear
        encryptedFieldName = isExpiryDateField ? encryptedType : pFieldType; // encryptedCardNumber, encryptedSecurityCode, encryptedExpiryMonth, encryptedExpiryYear
        // Create objects to broadcast valid state
        var callbackObj = makeCallbackObj(pFieldType, encryptedFieldName, uuid, false, pTxVariant, pRootNode);
        callbackObjectsArr.push(callbackObj);
    }
    return callbackObjectsArr;
};
export var makeCallbackObjectsEncryption = function (pFieldType, pTxVariant, pRootNode, pEncryptedObjArr) {
    var i;
    var uuid;
    var encryptedObj;
    var encryptedFieldName;
    var encryptedBlob;
    var callbackObjectsArr = [];
    for (i = 0; i < pEncryptedObjArr.length; i += 1) {
        encryptedObj = pEncryptedObjArr[i];
        encryptedFieldName = encryptedObj.encryptedFieldName;
        uuid = pTxVariant + "-encrypted-" + encryptedFieldName;
        encryptedBlob = encryptedObj.blob;
        // Create objects to broadcast valid state
        var callbackObj = makeCallbackObj(pFieldType, encryptedFieldName, uuid, true, pTxVariant, pRootNode);
        callbackObj.blob = encryptedBlob;
        callbackObjectsArr.push(callbackObj);
    }
    return callbackObjectsArr;
};
