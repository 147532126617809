import { __assign } from "tslib";
import { h } from 'preact';
import CampaignContent from './CampaignContent';
export default function CampaignContainer(props) {
    var url = props.url;
    return (h("div", { className: "adyen-checkout__campaign" },
        !url && h(CampaignContent, __assign({}, props)),
        url && (h("a", { href: url, className: "adyen-checkout__campaign-link", target: "_blank", rel: "noopener noreferrer" },
            h(CampaignContent, __assign({}, props))))));
}
