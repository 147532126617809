import { __extends } from "tslib";
import OpenInvoiceContainer from '../helpers/OpenInvoiceContainer';
var FacilyPay3x = /** @class */ (function (_super) {
    __extends(FacilyPay3x, _super);
    function FacilyPay3x() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FacilyPay3x.type = 'facilypay_3x';
    return FacilyPay3x;
}(OpenInvoiceContainer));
export default FacilyPay3x;
