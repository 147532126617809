import { __assign } from "tslib";
import { filterAllowedPaymentMethods, filterEcomStoredPaymentMethods, filterRemovedPaymentMethods, filterSupportedStoredPaymentMethods } from './filters';
var processStoredPaymentMethod = function (pm) { return (__assign(__assign({}, pm), { storedPaymentMethodId: pm.id })); };
export var processPaymentMethods = function (paymentMethodsResponse, _a) {
    var _b = _a.allowPaymentMethods, allowPaymentMethods = _b === void 0 ? [] : _b, _c = _a.removePaymentMethods, removePaymentMethods = _c === void 0 ? [] : _c;
    var _d = paymentMethodsResponse.paymentMethods, paymentMethods = _d === void 0 ? [] : _d;
    return paymentMethods.filter(filterAllowedPaymentMethods, allowPaymentMethods).filter(filterRemovedPaymentMethods, removePaymentMethods);
};
export var processStoredPaymentMethods = function (paymentMethodsResponse, _a) {
    if (paymentMethodsResponse === void 0) { paymentMethodsResponse = {}; }
    var _b = _a.allowPaymentMethods, allowPaymentMethods = _b === void 0 ? [] : _b, _c = _a.removePaymentMethods, removePaymentMethods = _c === void 0 ? [] : _c;
    var _d = paymentMethodsResponse.storedPaymentMethods, storedPaymentMethods = _d === void 0 ? [] : _d;
    return storedPaymentMethods
        .filter(filterSupportedStoredPaymentMethods) // only display supported stored payment methods
        .filter(filterAllowedPaymentMethods, allowPaymentMethods)
        .filter(filterRemovedPaymentMethods, removePaymentMethods)
        .filter(filterEcomStoredPaymentMethods) // Only accept Ecommerce shopper interactions
        .map(processStoredPaymentMethod);
};
export var checkPaymentMethodsResponse = function (response) {
    var _a, _b;
    if (typeof response === 'string') {
        throw new Error('paymentMethodsResponse was provided but of an incorrect type (should be an object but a string was provided).' +
            'Try JSON.parse("{...}") your paymentMethodsResponse.');
    }
    if (response instanceof Array) {
        throw new Error('paymentMethodsResponse was provided but of an incorrect type (should be an object but an array was provided).' +
            'Please check you are passing the whole response.');
    }
    if (!((_a = response === null || response === void 0 ? void 0 : response.paymentMethods) === null || _a === void 0 ? void 0 : _a.length) && !((_b = response === null || response === void 0 ? void 0 : response.storePaymentMethods) === null || _b === void 0 ? void 0 : _b.length)) {
        console.warn('paymentMethodsResponse was provided but no payment methods were found.');
    }
};
