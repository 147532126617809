export var validateHolderName = function (holderName, holderNameRequired) {
    if (holderNameRequired === void 0) { holderNameRequired = false; }
    if (holderNameRequired) {
        return !!holderName && typeof holderName === 'string' && holderName.trim().length > 0;
    }
    return true;
};
export default {
    validateHolderName: validateHolderName
};
