import { __extends } from "tslib";
import { Component, h } from 'preact';
import cx from 'classnames';
import styles from './ApplePayButton.module.scss';
import './ApplePayButton.scss';
var ApplePayButton = /** @class */ (function (_super) {
    __extends(ApplePayButton, _super);
    function ApplePayButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ApplePayButton.prototype.render = function (_a) {
        var buttonColor = _a.buttonColor, buttonType = _a.buttonType;
        /* eslint-disable jsx-a11y/no-static-element-interactions */
        return (h("div", { className: cx('adyen-checkout__applepay__button', "adyen-checkout__applepay__button--" + buttonColor, "adyen-checkout__applepay__button--" + buttonType, [styles['apple-pay-button']], [styles["apple-pay-button-" + buttonColor]], [styles["apple-pay-button--type-" + buttonType]]), onClick: this.props.onClick }));
        /* eslint-enable jsx-a11y/no-static-element-interactions */
    };
    ApplePayButton.defaultProps = {
        onClick: function () { },
        buttonColor: 'black',
        buttonType: 'plain'
    };
    return ApplePayButton;
}(Component));
export default ApplePayButton;
