var phoneNumberRegEx = /^[+]*[0-9]{1,4}[\s/0-9]*$/;
export var mbwayValidationRules = {
    input: {
        telephoneNumber: function (num) {
            // Format
            var regEx = /[^0-9+\s]/g;
            var formattedVal = num.replace(regEx, '');
            // Validate
            var isValid = phoneNumberRegEx.test(formattedVal) && formattedVal && formattedVal.length >= 7;
            return { isValid: isValid, value: formattedVal, showError: false };
        },
        default: function (value) { return value && value.length > 0; }
    },
    blur: {
        telephoneNumber: function (num) {
            // Just validate
            return { isValid: phoneNumberRegEx.test(num) && num && num.length >= 7, value: num, showError: true };
        },
        default: function (value) { return value && value.length > 0; }
    }
};
