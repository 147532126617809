import { __extends } from "tslib";
import OpenInvoiceContainer from '../helpers/OpenInvoiceContainer';
var FacilyPay10x = /** @class */ (function (_super) {
    __extends(FacilyPay10x, _super);
    function FacilyPay10x() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FacilyPay10x.type = 'facilypay_10x';
    return FacilyPay10x;
}(OpenInvoiceContainer));
export default FacilyPay10x;
