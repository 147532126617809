import { checkPaymentMethodsResponse, processPaymentMethods, processStoredPaymentMethods } from './utils';
var PaymentMethodsResponse = /** @class */ (function () {
    function PaymentMethodsResponse(response, options) {
        if (options === void 0) { options = {}; }
        this.paymentMethods = [];
        this.storedPaymentMethods = [];
        checkPaymentMethodsResponse(response);
        this.paymentMethods = response ? processPaymentMethods(response, options) : [];
        this.storedPaymentMethods = response ? processStoredPaymentMethods(response, options) : [];
    }
    PaymentMethodsResponse.prototype.has = function (paymentMethod) {
        return Boolean(this.paymentMethods.find(function (pm) { return pm.type === paymentMethod; }));
    };
    PaymentMethodsResponse.prototype.find = function (paymentMethod) {
        return this.paymentMethods.find(function (pm) { return pm.type === paymentMethod; });
    };
    return PaymentMethodsResponse;
}());
export default PaymentMethodsResponse;
