import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import GooglePayService from './GooglePayService';
import GooglePayButton from './components/GooglePayButton';
import defaultProps from './defaultProps';
var GooglePay = /** @class */ (function (_super) {
    __extends(GooglePay, _super);
    function GooglePay() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.googlePay = new GooglePayService(_this.props);
        _this.loadPayment = function () {
            var _a = _this.props, _b = _a.onSubmit, onSubmit = _b === void 0 ? function () { } : _b, _c = _a.onAuthorized, onAuthorized = _c === void 0 ? function () { } : _c;
            return _this.googlePay
                .initiatePayment(_this.props)
                .then(function (paymentData) {
                // setState will trigger an onChange event
                _this.setState({
                    googlePayToken: paymentData.paymentMethodData.tokenizationData.token,
                    googlePayCardNetwork: paymentData.paymentMethodData.info.cardNetwork
                });
                onSubmit({ data: _this.data, isValid: _this.isValid }, _this);
                return onAuthorized(paymentData);
            })
                .catch(function (error) {
                _this.props.onError(error);
                return Promise.reject(error);
            });
        };
        _this.submit = function () {
            return _this.loadPayment();
        };
        _this.startPayment = function () {
            return _this.loadPayment();
        };
        /**
         * Determine a shopper's ability to return a form of payment from the Google Pay API.
         */
        _this.isAvailable = function () {
            return _this.isReadyToPay()
                .then(function (response) {
                if (!response.result) {
                    throw new Error('Google Pay is not available');
                }
                if (response.paymentMethodPresent === false) {
                    throw new Error('Google Pay - No paymentMethodPresent');
                }
                return true;
            })
                .catch(function () {
                return false;
            });
        };
        /**
         * Determine a shopper's ability to return a form of payment from the Google Pay API.
         */
        _this.isReadyToPay = function () {
            return _this.googlePay.isReadyToPay(_this.props);
        };
        /**
         * Use this method to prefetch a PaymentDataRequest configuration to improve loadPaymentData execution time on later user interaction. No value is returned.
         */
        _this.prefetch = function () {
            return _this.googlePay.prefetchPaymentData(_this.props);
        };
        return _this;
    }
    /**
     * Formats the component data input
     * For legacy support - maps configuration.merchantIdentifier to configuration.merchantId
     */
    GooglePay.prototype.formatProps = function (props) {
        var configuration = props.configuration;
        var merchantIdentifier = configuration.merchantIdentifier;
        return __assign(__assign({}, props), { showButton: props.showPayButton === true, configuration: __assign(__assign({}, configuration), (merchantIdentifier && { merchantId: merchantIdentifier })) });
    };
    /**
     * Formats the component data output
     */
    GooglePay.prototype.formatData = function () {
        return {
            paymentMethod: __assign({ type: GooglePay.type }, this.state)
        };
    };
    Object.defineProperty(GooglePay.prototype, "isValid", {
        /**
         * Validation
         */
        get: function () {
            return !!this.state.googlePayToken;
        },
        enumerable: false,
        configurable: true
    });
    GooglePay.prototype.render = function () {
        if (this.props.showButton) {
            return (h(GooglePayButton, { buttonColor: this.props.buttonColor, buttonType: this.props.buttonType, paymentsClient: this.googlePay.paymentsClient, onClick: this.submit }));
        }
        return null;
    };
    GooglePay.type = 'paywithgoogle';
    GooglePay.defaultProps = defaultProps;
    return GooglePay;
}(UIElement));
export default GooglePay;
