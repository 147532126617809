import postMessageToIframe from './postMessageToIframe';
// UTIL TO BROADCAST TO ALL IFRAMES AT ONCE
// Adds correct txVariant, fieldType and numKey for each iframe
export function postMessageToAllIframes(pDataObj) {
    var _this = this;
    var objKeys = Object.keys(pDataObj || {});
    if (!objKeys.length) {
        // pDataObj is an object with the 'special' key(s) that represent the reason for making this postMessage
        // without it/them there is no reason to postMessage
        return;
    }
    var securedFieldKeys = Object.keys(this.state.securedFields);
    securedFieldKeys.forEach(function (pFieldType) {
        var dataObj = {
            txVariant: _this.state.type,
            fieldType: pFieldType,
            numKey: _this.state.securedFields[pFieldType].numKey
        };
        // Copy across 'special' properties from passed data object
        objKeys.forEach(function (pKey) {
            dataObj[pKey] = pDataObj[pKey];
        });
        postMessageToIframe(dataObj, _this.getIframeContentWin(pFieldType), _this.config.loadingContext);
    });
}
