import { h } from 'preact';
import { getImageUrl } from '../../../../utils/get-image';
import Img from '../../../internal/Img';
import useCoreContext from '../../../../core/Context/useCoreContext';
var Error = function (_a) {
    var message = _a.message;
    var _b = useCoreContext(), loadingContext = _b.loadingContext, i18n = _b.i18n;
    return (h("div", { className: "adyen-checkout__status adyen-checkout__status--error" },
        h(Img, { className: "adyen-checkout__status__icon", src: getImageUrl({ loadingContext: loadingContext, imageFolder: 'components/' })('error'), alt: i18n.get(message || 'error.message.unknown'), height: "88" }),
        h("span", { className: "adyen-checkout__status__text" }, i18n.get(message || 'error.message.unknown'))));
};
export default Error;
