/**
 *  Used to retrieve the origin from a url
 *
 *  @remarks
 *  Uses a regex to get origin (can't handle localhost origins)
 *
 *  @param url - URL
 *  @returns The origin of the url
 */
export var getOrigin = function (url) {
    var originRegex = /^(?:([A-Za-z]+):)?(\/{0,3})([0-9.\-A-Za-z]+)(?::(\d+))?(?:\/([^?#]*))?(?:\?([^#]*))?(?:#(.*))?$/;
    var matches = originRegex.exec(url);
    if (!matches)
        return null;
    var protocol = matches[1], separator = matches[2], host = matches[3], port = matches[4];
    if (!protocol || !separator || !host)
        return null;
    return protocol + ":" + separator + host + (port ? ":" + port : '');
};
export default getOrigin;
