var EventEmitter = /** @class */ (function () {
    function EventEmitter() {
        var _this = this;
        this.events = {};
        this.on = function (eventName, fn) {
            _this.events[eventName] = _this.events[eventName] || [];
            _this.events[eventName].push(fn);
        };
        this.off = function (eventName, fn) {
            if (_this.events[eventName]) {
                _this.events[eventName] = _this.events[eventName].reduce(function (acc, cur) {
                    if (cur !== fn)
                        acc.push(cur);
                    return acc;
                }, []);
            }
        };
        this.emit = function (eventName, data) {
            if (_this.events[eventName]) {
                _this.events[eventName].forEach(function (fn) {
                    fn(data);
                });
            }
        };
    }
    return EventEmitter;
}());
export default EventEmitter;
