import { __assign, __spreadArrays } from "tslib";
import { useRef, useState, useEffect } from 'preact/hooks';
import cx from 'classnames';
import './Img.scss';
import { h } from 'preact';
export default function Img(props) {
    var _a = props.backgroundUrl, backgroundUrl = _a === void 0 ? '' : _a, _b = props.className, className = _b === void 0 ? '' : _b, _c = props.classNameModifiers, classNameModifiers = _c === void 0 ? [] : _c, _d = props.src, src = _d === void 0 ? '' : _d, _e = props.alt, alt = _e === void 0 ? '' : _e, _f = props.showOnError, showOnError = _f === void 0 ? false : _f;
    var _g = useState(false), loaded = _g[0], setLoaded = _g[1];
    var imageRef = useRef(null);
    var handleLoad = function () {
        setLoaded(true);
    };
    var handleError = function () {
        setLoaded(showOnError);
    };
    var classNames = cx.apply(void 0, __spreadArrays([[className],
        'adyen-checkout__image',
        { 'adyen-checkout__image--loaded': loaded }], classNameModifiers.map(function (modifier) { return "adyen-checkout__image--" + modifier; })));
    useEffect(function () {
        var image = backgroundUrl ? new Image() : imageRef.current;
        image.src = backgroundUrl || src;
        image.onload = handleLoad;
        setLoaded(!!image.complete);
    }, []);
    if (backgroundUrl) {
        return h("div", __assign({ style: { backgroundUrl: backgroundUrl } }, props, { className: classNames }));
    }
    return h("img", __assign({}, props, { alt: alt, ref: imageRef, className: classNames, onError: handleError }));
}
