import { __assign } from "tslib";
import { h } from 'preact';
import { useState, useEffect, useMemo } from 'preact/hooks';
import { renderFormField } from '../../../../internal/FormFields';
import classNames from 'classnames';
import Field from '../../../../internal/FormFields/Field';
import useCoreContext from '../../../../../core/Context/useCoreContext';
import styles from '../CardInput.module.scss';
export default function KCPAuthentication(props) {
    var _a;
    var i18n = useCoreContext().i18n;
    var isTaxNumberValid = function (taxNumber) {
        if (taxNumber === void 0) { taxNumber = ''; }
        return taxNumber.length === 6 || taxNumber.length === 10;
    };
    var _b = useState({ taxNumber: props.taxNumber }), data = _b[0], setData = _b[1];
    var _c = useState({ taxNumber: isTaxNumberValid(props.taxNumber) }), valid = _c[0], setValid = _c[1];
    var _d = useState({}), errors = _d[0], setErrors = _d[1];
    var taxNumberLabel = useMemo(function () {
        var _a;
        if (((_a = data.taxNumber) === null || _a === void 0 ? void 0 : _a.length) > 6)
            return i18n.get('creditCard.taxNumber.labelAlt');
        return i18n.get('creditCard.taxNumber.label');
    }, [data.taxNumber]);
    var handleTaxNumber = function (e) {
        setData(__assign(__assign({}, data), { taxNumber: e.target.value }));
        setValid(__assign(__assign({}, valid), { taxNumber: isTaxNumberValid(e.target.value) }));
        setErrors(__assign(__assign({}, errors), { taxNumber: false }));
    };
    useEffect(function () {
        props.onChange(data, valid);
    }, [data.taxNumber]);
    this.showValidation = function () {
        setErrors({ taxNumber: !isTaxNumberValid(data.taxNumber) });
    };
    return (h("div", { className: "adyen-checkout__card__kcp-authentication" },
        h(Field, { label: taxNumberLabel, filled: props.filled, classNameModifiers: ['kcp-taxNumber'], errorMessage: errors.taxNumber && i18n.get('creditCard.taxNumber.invalid'), isValid: valid.taxNumber }, renderFormField('tel', {
            className: "adyen-checkout__card__kcp-taxNumber__input " + styles['adyen-checkout__input'],
            placeholder: i18n.get('creditCard.taxNumber.placeholder'),
            maxLength: 10,
            minLength: 6,
            autoComplete: false,
            value: data.taxNumber,
            required: true,
            onChange: handleTaxNumber,
            onInput: handleTaxNumber
        })),
        h(Field, { label: i18n.get('creditCard.encryptedPassword.label'), focused: props.focusedElement === 'encryptedPassword', filled: props.filled, classNameModifiers: ['50', 'koreanAuthentication-encryptedPassword'], onFocusField: function () { return props.onFocusField('encryptedPassword'); }, errorMessage: props.encryptedPasswordState.errors && i18n.get('creditCard.encryptedPassword.invalid'), isValid: props.encryptedPasswordState.valid },
            h("span", { "data-cse": "encryptedPassword", className: classNames((_a = {
                        'adyen-checkout__input': true,
                        'adyen-checkout__input--large': true
                    },
                    _a[styles['adyen-checkout__input']] = true,
                    _a['adyen-checkout__input--error'] = props.encryptedPasswordState.errors,
                    _a['adyen-checkout__input--valid'] = props.encryptedPasswordState.valid,
                    _a['adyen-checkout__input--focus'] = props.focusedElement === 'encryptedPassword',
                    _a)) }))));
}
