import { __assign, __extends } from "tslib";
import { h } from 'preact';
import OpenInvoiceContainer from '../helpers/OpenInvoiceContainer';
import ConsentCheckboxLabel from './components/ConsentCheckboxLabel';
import { AFTERPAY_B2B_CONSENT_URL } from './config';
var AfterPayB2B = /** @class */ (function (_super) {
    __extends(AfterPayB2B, _super);
    function AfterPayB2B() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AfterPayB2B.prototype.formatProps = function (props) {
        return __assign(__assign({}, _super.prototype.formatProps.call(this, props)), { consentCheckboxLabel: h(ConsentCheckboxLabel, { url: AFTERPAY_B2B_CONSENT_URL }) });
    };
    AfterPayB2B.type = 'afterpay_b2b';
    AfterPayB2B.defaultProps = {
        onChange: function () { },
        data: { companyDetails: {}, personalDetails: {}, billingAddress: {}, deliveryAddress: {} },
        visibility: {
            companyDetails: 'editable',
            personalDetails: 'editable',
            billingAddress: 'editable',
            deliveryAddress: 'editable'
        }
    };
    return AfterPayB2B;
}(OpenInvoiceContainer));
export default AfterPayB2B;
