import { h } from 'preact';
import { useState, useLayoutEffect } from 'preact/hooks';
import { renderFormField } from '../../FormFields';
import Field from '../../FormFields/Field';
import useCoreContext from '../../../../core/Context/useCoreContext';
import fetchJSONData from '../../../../utils/fetch-json-data';
export default function CountryField(props) {
    var _a = props.allowedCountries, allowedCountries = _a === void 0 ? [] : _a, errorMessage = props.errorMessage, onDropdownChange = props.onDropdownChange, value = props.value;
    var _b = useCoreContext(), i18n = _b.i18n, loadingContext = _b.loadingContext;
    var _c = useState([]), countries = _c[0], setCountries = _c[1];
    var _d = useState(false), loaded = _d[0], setLoaded = _d[1];
    var _e = useState(props.readOnly), readOnly = _e[0], setReadOnly = _e[1];
    useLayoutEffect(function () {
        fetchJSONData({
            path: "datasets/countries/" + i18n.locale + ".json",
            loadingContext: loadingContext
        })
            .then(function (response) {
            var countriesFilter = function (country) { return allowedCountries.includes(country.id); };
            var newCountries = allowedCountries.length ? response.filter(countriesFilter) : response;
            setCountries(newCountries || []);
            setReadOnly(newCountries.length === 1 || readOnly);
            setLoaded(true);
        })
            .catch(function (error) {
            console.error(error);
            setCountries([]);
            setLoaded(true);
        });
    }, []);
    if (!loaded)
        return null;
    return (h(Field, { label: i18n.get('country'), errorMessage: errorMessage, classNameModifiers: ['country'] }, renderFormField('select', {
        onChange: onDropdownChange,
        name: 'country',
        placeholder: i18n.get('select.country'),
        selected: value,
        items: countries,
        readonly: readOnly && !!value
    })));
}
