import { __extends } from "tslib";
import { Component, h } from 'preact';
import Iframe from '../../../internal/IFrame';
import Spinner from '../../../internal/Spinner';
import ThreeDS2Form from '../Form';
import promiseTimeout from '../../../../utils/promiseTimeout';
import getProcessMessageHandler from '../../../../utils/get-process-message-handler';
import { FAILED_METHOD_STATUS_RESOLVE_OBJECT, THREEDS_METHOD_TIMEOUT, FAILED_METHOD_STATUS_RESOLVE_OBJECT_TIMEOUT } from '../../config';
import { encodeBase64URL } from '../utils';
var iframeName = 'threeDSMethodIframe';
/**
 * Create and Base64URL encode a JSON object containing the serverTransactionID & threeDSMethodNotificationURL.
 * This Base64URL string will be added to the <form> in the ThreeDS2Form component.
 * The ThreeDS2Form component will submit the <form> when it mounts, using the ThreeDS2Iframe as the <form> target.
 * getProcessMessageHandler will listen for the postMessage response from the notificationURL and will settle the
 * promise accordingly causing this component to call the appropriate callback.
 * The callbacks exist in the parent component: ThreeDS2DeviceFingerprint where they ultimately call the onComplete
 * function passed as a prop when checkout.create('threeDS2DeviceFingerprint') is called.
 */
var Get3DS2DeviceFingerprint = /** @class */ (function (_super) {
    __extends(Get3DS2DeviceFingerprint, _super);
    function Get3DS2DeviceFingerprint(props) {
        var _this = _super.call(this, props) || this;
        /**
         * Create and Base64URL encode a JSON object
         */
        var dataObj = {
            threeDSServerTransID: _this.props.serverTransactionID,
            threeDSMethodNotificationURL: _this.props.threedsMethodNotificationURL
        };
        var jsonStr = JSON.stringify(dataObj);
        var base64URLencodedData = encodeBase64URL(jsonStr);
        _this.state = { base64URLencodedData: base64URLencodedData };
        return _this;
    }
    Get3DS2DeviceFingerprint.prototype.get3DS2MethodPromise = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            /**
             * Listen for postMessage responses from the notification url
             */
            _this.processMessageHandler = getProcessMessageHandler(_this.props.postMessageDomain, resolve, reject, FAILED_METHOD_STATUS_RESOLVE_OBJECT, 'fingerPrintResult');
            /* eslint-disable-next-line */
            window.addEventListener('message', _this.processMessageHandler);
        });
    };
    Get3DS2DeviceFingerprint.prototype.componentDidMount = function () {
        var _this = this;
        // Check 3DS2 Device fingerprint
        this.fingerPrintPromise = promiseTimeout(THREEDS_METHOD_TIMEOUT, this.get3DS2MethodPromise(), FAILED_METHOD_STATUS_RESOLVE_OBJECT_TIMEOUT);
        this.fingerPrintPromise.promise
            .then(function (resolveObject) {
            window.removeEventListener('message', _this.processMessageHandler);
            _this.props.onCompleteFingerprint(resolveObject);
        })
            .catch(function (rejectObject) {
            window.removeEventListener('message', _this.processMessageHandler);
            _this.props.onErrorFingerprint(rejectObject);
        });
    };
    Get3DS2DeviceFingerprint.prototype.componentWillUnmount = function () {
        this.fingerPrintPromise.cancel();
        window.removeEventListener('message', this.processMessageHandler);
    };
    Get3DS2DeviceFingerprint.prototype.render = function (_a, _b) {
        var methodURL = _a.methodURL;
        var base64URLencodedData = _b.base64URLencodedData;
        return (h("div", { className: "adyen-checkout__3ds2-device-fingerprint" },
            this.props.showSpinner && h(Spinner, null),
            h("div", { style: { display: 'none' } },
                h(Iframe, { name: iframeName }),
                h(ThreeDS2Form, { name: 'threeDSMethodForm', action: methodURL, target: iframeName, inputName: 'threeDSMethodData', inputValue: base64URLencodedData }))));
    };
    Get3DS2DeviceFingerprint.defaultProps = {
        showSpinner: true
    };
    return Get3DS2DeviceFingerprint;
}(Component));
export default Get3DS2DeviceFingerprint;
