/**
 * Tests if a passed promise settles before a certain amount of time has elapsed
 *
 * @param ms - number of milliseconds the passed promise has to settle
 * @param promise - the passed promise
 * @param timeOutObject - the object that the promiseTimeout will reject with if the passed promise doesn't settle in time
 */
var promiseTimeout = function (ms, promise, timeOutObject) {
    var timer;
    var promiseTimer = new Promise(function (resolve, reject) {
        // Create a timeout to reject promise if not resolved
        /* eslint-disable-next-line */
        timer = setTimeout(function () {
            reject(timeOutObject);
        }, ms);
        promise
            .then(function (res) {
            clearTimeout(timer);
            resolve(res);
        })
            .catch(function (err) {
            clearTimeout(timer);
            reject(err);
        });
    });
    var cancelTimer = function () {
        clearTimeout(timer);
    };
    return {
        promise: promiseTimer,
        cancel: cancelTimer
    };
};
export default promiseTimeout;
