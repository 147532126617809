import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../../UIElement';
import OpenInvoice from '../../internal/OpenInvoice';
import CoreProvider from '../../../core/Context/CoreProvider';
import { unformatDate } from '../../internal/FormFields/InputDate/utils';
var OpenInvoiceContainer = /** @class */ (function (_super) {
    __extends(OpenInvoiceContainer, _super);
    function OpenInvoiceContainer() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(OpenInvoiceContainer.prototype, "isValid", {
        /**
         * Returns whether the component state is valid or not
         */
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Formats props on construction time
     */
    OpenInvoiceContainer.prototype.formatProps = function (props) {
        var _a, _b;
        var country = props.countryCode || ((_b = (_a = props.data) === null || _a === void 0 ? void 0 : _a.billingAddress) === null || _b === void 0 ? void 0 : _b.countryCode);
        return __assign(__assign({}, props), { allowedCountries: [country], visibility: __assign(__assign({}, OpenInvoiceContainer.defaultProps.visibility), props.visibility), data: __assign(__assign({}, props.data), { billingAddress: __assign(__assign({}, props.data.billingAddress), { country: country }), deliveryAddress: __assign(__assign({}, props.data.deliveryAddress), { country: country }) }) });
    };
    /**
     * Formats the component data output
     */
    OpenInvoiceContainer.prototype.formatData = function () {
        var _a = this.state.data, data = _a === void 0 ? {} : _a;
        var _b = data.companyDetails, companyDetails = _b === void 0 ? {} : _b, _c = data.personalDetails, personalDetails = _c === void 0 ? {} : _c, billingAddress = data.billingAddress, deliveryAddress = data.deliveryAddress;
        var name = companyDetails.name, registrationNumber = companyDetails.registrationNumber;
        var firstName = personalDetails.firstName, lastName = personalDetails.lastName, _d = personalDetails.gender, gender = _d === void 0 ? 'UNKNOWN' : _d, telephoneNumber = personalDetails.telephoneNumber, shopperEmail = personalDetails.shopperEmail, dateOfBirth = personalDetails.dateOfBirth;
        return __assign(__assign(__assign(__assign(__assign(__assign(__assign({ paymentMethod: {
                type: this.constructor['type']
            } }, ((name || registrationNumber) && {
            company: __assign(__assign({}, (name && { name: name })), (registrationNumber && { registrationNumber: registrationNumber }))
        })), { shopperName: __assign(__assign(__assign({}, (firstName && { firstName: firstName })), (lastName && { lastName: lastName })), (gender && { gender: gender })) }), (dateOfBirth && { dateOfBirth: unformatDate(dateOfBirth) })), (telephoneNumber && { telephoneNumber: telephoneNumber })), (shopperEmail && { shopperEmail: shopperEmail })), ((billingAddress === null || billingAddress === void 0 ? void 0 : billingAddress.country) && { countryCode: billingAddress.country })), { billingAddress: billingAddress, deliveryAddress: deliveryAddress || billingAddress });
    };
    OpenInvoiceContainer.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
            h(OpenInvoice, __assign({ ref: function (ref) {
                    _this.componentRef = ref;
                } }, this.props, this.state, { consentCheckbox: this.props.consentCheckbox, onChange: this.setState, onSubmit: this.submit, payButton: this.payButton }))));
    };
    OpenInvoiceContainer.defaultProps = {
        onChange: function () { },
        data: { companyDetails: {}, personalDetails: {}, billingAddress: {}, deliveryAddress: {} },
        visibility: {
            companyDetails: 'hidden',
            personalDetails: 'editable',
            billingAddress: 'editable',
            deliveryAddress: 'editable'
        }
    };
    return OpenInvoiceContainer;
}(UIElement));
export default OpenInvoiceContainer;
