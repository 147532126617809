import { __assign } from "tslib";
import { h } from 'preact';
import InputText from './InputText';
import InputDate from './InputDate';
import InputTelephone from './InputTelephone';
import InputEmail from './InputEmail';
import RadioGroup from './RadioGroup';
import Checkbox from './Checkbox';
import Select from './Select';
import './FormFields.scss';
export var renderFormField = function (type, props) {
    var formFieldTypes = {
        boolean: Checkbox,
        date: InputDate,
        emailAddress: InputEmail,
        radio: RadioGroup,
        select: Select,
        tel: InputTelephone,
        text: InputText,
        default: InputText
    };
    var FormInputElement = formFieldTypes[type] || formFieldTypes.default;
    return h(FormInputElement, __assign({}, props));
};
export default renderFormField;
