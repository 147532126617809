import { __extends } from "tslib";
import { Component, h } from 'preact';
import classNames from 'classnames';
import Iframe from '../../../internal/IFrame';
import Spinner from '../../../internal/Spinner';
import ThreeDS2Form from '../Form';
import getProcessMessageHandler from '../../../../utils/get-process-message-handler';
import { encodeBase64URL } from '../utils';
import promiseTimeout from '../../../../utils/promiseTimeout';
import { CHALLENGE_TIMEOUT, UNKNOWN_CHALLENGE_RESOLVE_OBJECT_TIMEOUT, UNKNOWN_CHALLENGE_RESOLVE_OBJECT } from '../../config';
var iframeName = 'threeDSIframe';
var Do3DS2Challenge = /** @class */ (function (_super) {
    __extends(Do3DS2Challenge, _super);
    function Do3DS2Challenge(props) {
        var _this = _super.call(this, props) || this;
        _this.iframeCallback = function () {
            _this.setState({ status: 'iframeLoaded' });
        };
        /**
         * Create and Base64Url encode a JSON object containing the serverTransactionID & threeDSMethodNotificationURL
         */
        var jsonStr = JSON.stringify(_this.props.cReqData);
        var base64URLencodedData = encodeBase64URL(jsonStr);
        _this.state = { base64URLencodedData: base64URLencodedData };
        return _this;
    }
    Do3DS2Challenge.prototype.get3DS2ChallengePromise = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            /**
             * Listen for postMessage responses from the notification url
             */
            _this.processMessageHandler = getProcessMessageHandler(_this.props.postMessageDomain, resolve, reject, UNKNOWN_CHALLENGE_RESOLVE_OBJECT, 'challengeResult');
            /* eslint-disable-next-line */
            window.addEventListener('message', _this.processMessageHandler);
        });
    };
    Do3DS2Challenge.prototype.componentDidMount = function () {
        var _this = this;
        // Render challenge
        this.challengePromise = promiseTimeout(CHALLENGE_TIMEOUT, this.get3DS2ChallengePromise(), UNKNOWN_CHALLENGE_RESOLVE_OBJECT_TIMEOUT);
        this.challengePromise.promise
            .then(function (resolveObject) {
            window.removeEventListener('message', _this.processMessageHandler);
            _this.props.onCompleteChallenge(resolveObject);
        })
            .catch(function (rejectObject) {
            window.removeEventListener('message', _this.processMessageHandler);
            _this.props.onErrorChallenge(rejectObject);
        });
    };
    Do3DS2Challenge.prototype.componentWillUnmount = function () {
        this.challengePromise.cancel();
        window.removeEventListener('message', this.processMessageHandler);
    };
    Do3DS2Challenge.prototype.render = function (_a, _b) {
        var acsURL = _a.acsURL, cReqData = _a.cReqData, iframeSizeArr = _a.iframeSizeArr;
        var base64URLencodedData = _b.base64URLencodedData, status = _b.status;
        var width = iframeSizeArr[0], height = iframeSizeArr[1];
        return (h("div", { className: classNames([
                'adyen-checkout__threeds2__challenge',
                "adyen-checkout__threeds2__challenge--" + cReqData.challengeWindowSize
            ]) },
            status !== 'iframeLoaded' && h(Spinner, null),
            h(Iframe, { name: iframeName, width: width, height: height, callback: this.iframeCallback }),
            h(ThreeDS2Form, { name: 'cReqForm', action: acsURL, target: iframeName, inputName: 'creq', inputValue: base64URLencodedData })));
    };
    return Do3DS2Challenge;
}(Component));
export default Do3DS2Challenge;
