import { h } from 'preact';
import './Spinner.scss';
/**
 * Default Loading Spinner
 * @param props -
 */
var Spinner = function (_a) {
    var _b = _a.inline, inline = _b === void 0 ? false : _b, _c = _a.size, size = _c === void 0 ? 'large' : _c;
    return (h("div", { className: "adyen-checkout__spinner__wrapper " + (inline ? 'adyen-checkout__spinner__wrapper--inline' : '') },
        h("div", { className: "adyen-checkout__spinner adyen-checkout__spinner--" + size })));
};
export default Spinner;
