import { __assign, __extends } from "tslib";
import { Component, h } from 'preact';
import classNames from 'classnames';
import Field from '../FormFields/Field';
import { renderFormField } from '../FormFields';
import { validatePhoneNumber } from './validate';
import './PhoneInput.scss';
var PhoneInput = /** @class */ (function (_super) {
    __extends(PhoneInput, _super);
    function PhoneInput(props) {
        var _a, _b, _c, _d;
        var _this = _super.call(this, props) || this;
        _this.handlePhoneInput = function (e) {
            e.preventDefault();
            var phoneNumber = e.target.value;
            var isPhoneNumberValid = validatePhoneNumber(phoneNumber, _this.props.minLength);
            _this.setState(function (prevState) { return ({
                data: __assign(__assign({}, prevState.data), { phoneNumber: phoneNumber }),
                errors: __assign(__assign({}, prevState.errors), { phoneNumber: !isPhoneNumberValid })
            }); }, _this.onChange);
        };
        _this.handlePrefixChange = function (e) {
            e.preventDefault();
            var phonePrefix = e.currentTarget.getAttribute('data-value');
            var isPrefixValid = !!phonePrefix;
            _this.setState(function (prevState) { return (__assign({ data: __assign(__assign({}, prevState.data), { phonePrefix: phonePrefix }) }, (isPrefixValid && { errors: __assign(__assign({}, prevState.errors), { phonePrefix: false }) }))); }, _this.onChange);
        };
        _this.showValidation = function () {
            var isPrefixValid = _this.props.items && _this.props.items.length ? !!_this.state.data.phonePrefix : true;
            var isPhoneNumberValid = validatePhoneNumber(_this.state.data.phoneNumber, _this.props.minLength);
            _this.setState({
                errors: {
                    phoneNumber: !isPhoneNumberValid,
                    phonePrefix: !isPrefixValid
                }
            });
        };
        _this.handlePrefixChange = _this.handlePrefixChange.bind(_this);
        _this.handlePhoneInput = _this.handlePhoneInput.bind(_this);
        _this.onChange = _this.onChange.bind(_this);
        _this.state = {
            data: {
                phonePrefix: _this.props.selected || ((_a = _this.props.data) === null || _a === void 0 ? void 0 : _a.phonePrefix),
                phoneNumber: ((_b = _this.props.data) === null || _b === void 0 ? void 0 : _b.phoneNumber) || ''
            },
            isValid: !!((_c = _this.props.data) === null || _c === void 0 ? void 0 : _c.phonePrefix) && !!((_d = _this.props.data) === null || _d === void 0 ? void 0 : _d.phoneNumber),
            errors: {}
        };
        return _this;
    }
    PhoneInput.prototype.onChange = function () {
        var _this = this;
        var isPrefixValid = this.props.items ? !!this.state.data.phonePrefix : true;
        var isPhoneNumberValid = validatePhoneNumber(this.state.data.phoneNumber, this.props.minLength);
        var isValid = isPrefixValid && isPhoneNumberValid;
        this.setState({ isValid: isValid }, function () {
            _this.props.onChange(_this.state);
        });
    };
    PhoneInput.prototype.render = function () {
        var showPrefix = !!this.props.items && this.props.items.length;
        return (h("div", { className: "adyen-checkout__phone-input" },
            h(Field, { errorMessage: !!this.state.errors.phoneNumber, label: this.props.i18n.get('telephoneNumber'), className: classNames({
                    'adyen-checkout__input--phone-number': true
                }), inputWrapperModifiers: ['phoneInput'] },
                h("div", { className: "adyen-checkout__input-wrapper" },
                    h("div", { className: "adyen-checkout__input" }, !!showPrefix && (h(Field, { inputWrapperModifiers: ['phoneInput'] },
                        renderFormField('select', {
                            className: 'adyen-checkout__dropdown--small adyen-checkout__countryFlag',
                            items: this.props.items,
                            name: this.props.prefixName,
                            onChange: this.handlePrefixChange,
                            placeholder: this.props.i18n.get('infix'),
                            selected: this.state.data.phonePrefix
                        }),
                        h("div", { className: "adyen-checkout__phoneNumber" },
                            h("div", null, this.state.data.phonePrefix),
                            h("input", { type: "tel", name: this.props.phoneName, value: this.state.data.phoneNumber, onInput: this.handlePhoneInput, placeholder: "123 456 789", className: "adyen-checkout__input adyen-checkout__input--phoneNumber", autoCorrect: "off" }))))))),
            this.props.showPayButton && this.props.payButton()));
    };
    PhoneInput.defaultProps = {
        onChange: function () { },
        onValid: function () { },
        phoneName: 'phoneNumber',
        prefixName: 'phonePrefix',
        selected: null,
        minLength: 3
    };
    return PhoneInput;
}(Component));
export default PhoneInput;
