export default {
    environment: 'TEST',
    // isReadyToPayRequest
    existingPaymentMethodRequired: false,
    // ButtonOptions
    // https://developers.google.com/pay/api/web/reference/object#ButtonOptions
    buttonColor: 'default',
    buttonType: 'long',
    showPayButton: true,
    // PaymentDataRequest
    configuration: {
        // Adyen's merchant account
        gatewayMerchantId: '',
        // https://developers.google.com/pay/api/web/reference/object#MerchantInfo
        merchantId: '',
        merchantName: '' // E.g Example Merchant,
    },
    // Payment
    amount: {
        value: 0,
        currency: 'USD'
    },
    countryCode: 'US',
    totalPriceStatus: 'FINAL',
    // Callbacks
    onError: function () { },
    onAuthorized: function (params) { return params; },
    onSubmit: function () { },
    // CardParameters
    // https://developers.google.com/pay/api/web/reference/object#CardParameters
    allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
    allowedCardNetworks: ['AMEX', 'DISCOVER', 'JCB', 'MASTERCARD', 'VISA'],
    allowCreditCards: true,
    allowPrepaidCards: true,
    billingAddressRequired: false,
    billingAddressParameters: undefined,
    emailRequired: false,
    shippingAddressRequired: false,
    shippingAddressParameters: undefined,
    shippingOptionRequired: false,
    shippingOptionParameters: undefined,
    paymentMethods: []
};
