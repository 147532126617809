import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import getImage from '../../utils/get-image';
import CoreProvider from '../../core/Context/CoreProvider';
import RedirectShopper from './components/RedirectShopper';
import RedirectButton from '../internal/RedirectButton';
/**
 * RedirectElement
 */
var RedirectElement = /** @class */ (function (_super) {
    __extends(RedirectElement, _super);
    function RedirectElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RedirectElement.prototype.formatProps = function (props) {
        return __assign(__assign({}, props), { showButton: !!props.showPayButton });
    };
    /**
     * Formats the component data output
     */
    RedirectElement.prototype.formatData = function () {
        return {
            paymentMethod: {
                type: this.props.type
            }
        };
    };
    Object.defineProperty(RedirectElement.prototype, "isValid", {
        /**
         * Returns whether the component state is valid or not
         */
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RedirectElement.prototype, "icon", {
        get: function () {
            return getImage({ loadingContext: this.props.loadingContext })(this.props.type);
        },
        enumerable: false,
        configurable: true
    });
    RedirectElement.prototype.render = function () {
        var _this = this;
        if (this.props.url && this.props.method) {
            return h(RedirectShopper, __assign({}, this.props));
        }
        if (this.props.showButton) {
            return (h(CoreProvider, __assign({}, this.props, { loadingContext: this.props.loadingContext }),
                h(RedirectButton, __assign({}, this.props, { onSubmit: this.submit, payButton: this.payButton, ref: function (ref) {
                        _this.componentRef = ref;
                    } }))));
        }
        return null;
    };
    RedirectElement.type = 'redirect';
    RedirectElement.defaultProps = {
        type: RedirectElement.type,
        showPayButton: true
    };
    return RedirectElement;
}(UIElement));
export default RedirectElement;
