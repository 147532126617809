import { h } from 'preact';
import Img from '../../../internal/Img';
import classNames from 'classnames';
import styles from '../DropinComponent.module.scss';
var PaymentMethodIcon = function (_a) {
    var src = _a.src, name = _a.name, _b = _a.disabled, disabled = _b === void 0 ? false : _b;
    return (h("span", { className: classNames('adyen-checkout__payment-method__image__wrapper', styles['adyen-checkout__payment-method__image__wrapper'], {
            'adyen-checkout__payment-method__image__wrapper--disabled': !!disabled
        }), "aria-hidden": "true" },
        h(Img, { className: "adyen-checkout__payment-method__image " + styles['adyen-checkout__payment-method__image'], src: src, alt: name, "aria-label": name, focusable: "false" })));
};
export default PaymentMethodIcon;
