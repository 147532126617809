export var COUNTDOWN_MINUTES = 15; // min
export var STATUS_INTERVAL = 2000; // ms
export var THROTTLE_TIME = 60000; // ms
export var THROTTLE_INTERVAL = 10000; // ms
export default {
    STATUS_INTERVAL: STATUS_INTERVAL,
    COUNTDOWN_MINUTES: COUNTDOWN_MINUTES,
    THROTTLE_TIME: THROTTLE_TIME,
    THROTTLE_INTERVAL: THROTTLE_INTERVAL,
    type: 'blik',
    messageTextId: 'blik.confirmPayment',
    awaitTextId: 'await.waitForConfirmation',
    showCountdownTimer: false
};
