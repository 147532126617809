import { ENCRYPTED_SECURITY_CODE, ENCRYPTED_CARD_NUMBER } from '../../configuration/constants';
import { existy } from '../../utilities/commonUtils';
import cardType from '../../utilities/cardType';
import postMessageToIframe from './iframes/postMessageToIframe';
export function sendBrandToCardSF(brand) {
    if (Object.prototype.hasOwnProperty.call(this.state.securedFields, ENCRYPTED_CARD_NUMBER)) {
        var dataObj = {
            txVariant: this.state.type,
            brand: brand,
            fieldType: ENCRYPTED_CARD_NUMBER,
            numKey: this.state.securedFields[ENCRYPTED_CARD_NUMBER].numKey
        };
        postMessageToIframe(dataObj, this.getIframeContentWin(ENCRYPTED_CARD_NUMBER), this.config.loadingContext);
    }
}
export function handleBrandFromBinLookup(brandsObj) {
    // The number of digits in number field has dropped below threshold for BIN lookup - so tell SF to reset & republish the brand it detects
    if (!brandsObj) {
        this.sendBrandToCardSF('reset');
        return;
    }
    var passedBrand = brandsObj.supportedBrands[0];
    var card = cardType.getCardByBrand(passedBrand);
    // If no card object force it to true, else base it on the value in the card object
    var cvcRequired = !existy(card) ? true : !(card.cvcRequired === false);
    var brandToSend = card ? passedBrand : 'card';
    // Create brand object to send to processBrand (& so handle brand change in the same way as if it had come from securedFields)
    var brandObj = {
        cvcRequired: cvcRequired,
        brand: brandToSend,
        hideCVC: !existy(card) ? false : card.hideCVC === true,
        cvcText: existy(card) && card.securityCode ? card.securityCode : 'Security code',
        fieldType: ENCRYPTED_CARD_NUMBER
    };
    this.processBrand(brandObj);
    // Pass brand to CardNumber SF
    this.sendBrandToCardSF(brandToSend);
    // CHECK IF BRAND CHANGE MEANS FORM IS NOW VALID e.g maestro/bcmc (which don't require cvc)
    // Set the cvcRequired value on the relevant SecuredFields instance...
    if (this.state.type === 'card' && Object.prototype.hasOwnProperty.call(this.state.securedFields, ENCRYPTED_SECURITY_CODE)) {
        this.state.securedFields[ENCRYPTED_SECURITY_CODE].cvcRequired = cvcRequired;
    }
    // ... and re-check if all SecuredFields are valid
    this.assessFormValidity();
}
