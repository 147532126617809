import { __assign } from "tslib";
import { h } from 'preact';
import { useMemo } from 'preact/hooks';
import InputBase from '../InputBase';
import { checkDateInputSupport, formatDate } from './utils';
export default function InputDate(props) {
    var isDateInputSupported = useMemo(checkDateInputSupport, []);
    var handleInput = function (e) {
        var value = e.target.value;
        e.target.value = formatDate(value);
        props.onInput(e);
    };
    if (isDateInputSupported) {
        return h(InputBase, __assign({}, props, { type: "date" }));
    }
    return h(InputBase, __assign({}, props, { onInput: handleInput, maxLength: 10 }));
}
