import { __extends } from "tslib";
import OpenInvoiceContainer from '../helpers/OpenInvoiceContainer';
var RatePay = /** @class */ (function (_super) {
    __extends(RatePay, _super);
    function RatePay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RatePay.type = 'ratepay';
    return RatePay;
}(OpenInvoiceContainer));
export default RatePay;
