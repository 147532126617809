export default {
    type: 'card',
    // Settings
    originKey: null,
    keypadFix: true,
    rootNode: null,
    loadingContext: null,
    groupTypes: [],
    allowedDOMAccess: false,
    showWarnings: false,
    autoFocus: true,
    trimTrailingSeparator: true,
    // Events
    onChange: function () { },
    onLoad: function () { },
    onConfigSuccess: function () { },
    onAllValid: function () { },
    onFieldValid: function () { },
    onBrand: function () { },
    onError: function () { },
    onBinValue: function () { },
    onFocus: function () { },
    onAutoComplete: function () { },
    // Customization
    placeholders: {},
    ariaLabels: {},
    styles: {}
};
