import { __assign } from "tslib";
/**
 * Log event to Adyen
 * @param config -
 */
var logTelemetry = function (config) { return function (event) {
    if (!config.accessKey) {
        return Promise.reject();
    }
    var telemetryEvent = __assign({ version: process.env.VERSION, platform: 'web', locale: config.locale, flavor: 'components', userAgent: navigator.userAgent, referrer: window.location.href, screenWidth: window.screen.width }, event);
    var options = {
        method: 'POST',
        headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(telemetryEvent)
    };
    return fetch(config.loadingContext + "v1/analytics/log?token=" + config.accessKey, options)
        .then(function (response) { return response.ok; })
        .catch(function () { });
}; };
export default logTelemetry;
