export default {
    details: [],
    type: 'ach',
    // Settings
    hasHolderName: true,
    holderNameRequired: true,
    billingAddressRequired: true,
    billingAddressAllowedCountries: ['US', 'PR'],
    // Events
    onLoad: function () { },
    onConfigSuccess: function () { },
    onAllValid: function () { },
    onFieldValid: function () { },
    onBrand: function () { },
    onError: function () { },
    onBinValue: function () { },
    onBlur: function () { },
    onFocus: function () { },
    onChange: function () { },
    originKey: null,
    // Values
    holderName: '',
    data: {
        holderName: '',
        billingAddress: {}
    },
    // Customization
    styles: {},
    placeholders: {},
    ariaLabels: {}
};
