import { ENCRYPTED_BANK_ACCNT_NUMBER_FIELD, ENCRYPTED_BANK_LOCATION_FIELD } from '../../../configuration/constants';
// ACH scenario: bankAccountNumber SF followed by a bankLocationId SF
export function shiftTabACH(fieldType) {
    var additionalField;
    var fieldToFocus;
    if (fieldType === ENCRYPTED_BANK_LOCATION_FIELD) {
        fieldToFocus = ENCRYPTED_BANK_ACCNT_NUMBER_FIELD;
    }
    return {
        fieldToFocus: fieldToFocus,
        additionalField: additionalField
    };
}
