import { __extends } from "tslib";
import IssuerListContainer from '../helpers/IssuerListContainer';
var DotpayElement = /** @class */ (function (_super) {
    __extends(DotpayElement, _super);
    function DotpayElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DotpayElement.type = 'dotpay';
    return DotpayElement;
}(IssuerListContainer));
export default DotpayElement;
