import { __extends } from "tslib";
import OpenInvoiceContainer from '../helpers/OpenInvoiceContainer';
var FacilyPay12x = /** @class */ (function (_super) {
    __extends(FacilyPay12x, _super);
    function FacilyPay12x() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FacilyPay12x.type = 'facilypay_12x';
    return FacilyPay12x;
}(OpenInvoiceContainer));
export default FacilyPay12x;
