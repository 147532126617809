import { __assign } from "tslib";
/**
 * Log event to Adyen
 * @param config - ready to be serialized and included in the request
 * @returns A log event function
 */
var logEvent = function (config) { return function (event) {
    var params = __assign({ version: process.env.VERSION, payload_version: 1, platform: 'web', locale: config.locale }, event);
    var queryString = Object.keys(params)
        .map(function (key) { return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]); })
        .join('&');
    new Image().src = config.loadingContext + "images/analytics.png?" + queryString;
}; };
export default logEvent;
