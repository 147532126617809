import { __extends } from "tslib";
import OpenInvoiceContainer from '../helpers/OpenInvoiceContainer';
var FacilyPay6x = /** @class */ (function (_super) {
    __extends(FacilyPay6x, _super);
    function FacilyPay6x() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FacilyPay6x.type = 'facilypay_6x';
    return FacilyPay6x;
}(OpenInvoiceContainer));
export default FacilyPay6x;
