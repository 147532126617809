export default {
    details: [],
    type: 'card',
    // Settings
    hasHolderName: false,
    holderNameRequired: false,
    enableStoreDetails: false,
    hideCVC: false,
    hasCVC: true,
    hasStoreDetails: false,
    storedDetails: false,
    showBrandIcon: true,
    billingAddressRequired: false,
    billingAddressRequiredFields: ['street', 'houseNumberOrName', 'postalCode', 'city', 'stateOrProvince', 'country'],
    installmentOptions: {},
    configuration: { koreanAuthenticationRequired: false },
    // Events
    onLoad: function () { },
    onConfigSuccess: function () { },
    onAllValid: function () { },
    onFieldValid: function () { },
    onBrand: function () { },
    onError: function () { },
    onBinValue: function () { },
    onBlur: function () { },
    onFocus: function () { },
    onChange: function () { },
    originKey: null,
    // Values
    holderName: '',
    data: {
        holderName: '',
        billingAddress: {}
    },
    // Customization
    styles: {},
    placeholders: {},
    ariaLabels: {}
};
