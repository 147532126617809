import { iso13616Prepare, iso7064Mod97_10, electronicFormat, regex, getIbanCountrySpecification } from './utils';
/**
 * Contains a validation status
 * @internal
 * @param status -
 * @param code -
 */
function ValidationStatus(status, code) {
    if (code === void 0) { code = null; }
    this.status = status;
    this.code = code;
}
/**
 * Validates the format of an iban
 * @internal
 * @param iban -
 */
var checkIbanStructure = function (iban) {
    var countryCode = iban.slice(0, 2);
    var ibanRegex = regex(iban, countryCode);
    return (ibanRegex.test && ibanRegex.test(iban.slice(4))) || false;
};
/**
 * Checks validity of an IBAN
 * @param iban -
 */
export var isValidIBAN = function (iban) {
    var electronicFormatIban = electronicFormat(iban);
    var preparedIban = iso13616Prepare(electronicFormatIban);
    var isValidISO = iso7064Mod97_10(preparedIban) === 1;
    return isValidISO && checkIbanStructure(electronicFormatIban);
};
/**
 * Checkss the validity status of an IBAN
 * @param iban -
 */
export var checkIbanStatus = function (iban) {
    var electronicFormatIban = electronicFormat(iban);
    if (iban.length < 2) {
        return new ValidationStatus('no-validate', 'TOO_SHORT'); // A
    }
    var countryCode = electronicFormatIban.slice(0, 2);
    var countrySpecification = getIbanCountrySpecification(countryCode);
    if (!countrySpecification) {
        return new ValidationStatus('invalid', 'INVALID_COUNTRY'); // AA13TEST0123456789
    }
    if (electronicFormatIban.length > countrySpecification.length) {
        return new ValidationStatus('invalid', 'TOO_LONG'); // NL13TEST01234567891
    }
    if (electronicFormatIban.length === countrySpecification.length) {
        if (isValidIBAN(iban)) {
            return new ValidationStatus('valid', 'VALID'); // NL13TEST0123456789
        }
        return new ValidationStatus('invalid', 'INVALID_IBAN'); // NL13TEST0123456781
    }
    return new ValidationStatus('no-validate', 'UNKNOWN'); // NL13TEST012345678
};
/**
 * Checks validity of a holder name
 * @param holder -
 */
export var isValidHolder = function (holder) { return !!(holder && holder.length && holder.length > 0); };
