import { __extends } from "tslib";
import { Component, h } from 'preact';
import GetDeviceFingerprint from './GetDeviceFingerprint';
import handleErrorCode from './utils';
import { DF_VERSION } from '../../constants';
var DeviceFingerprint = /** @class */ (function (_super) {
    __extends(DeviceFingerprint, _super);
    function DeviceFingerprint(props) {
        var _this = _super.call(this, props) || this;
        var accessKey = props.clientKey || props.originKey;
        if (accessKey) {
            _this.state = {
                status: 'retrievingFingerPrint',
                dfpURL: _this.props.loadingContext + "assets/html/" + accessKey + "/dfp." + DF_VERSION + ".html"
            };
        }
        return _this;
    }
    DeviceFingerprint.prototype.setStatusComplete = function (fingerprintResult) {
        var _this = this;
        this.setState({ status: 'complete' }, function () {
            _this.props.onComplete(fingerprintResult);
        });
    };
    DeviceFingerprint.prototype.render = function (_a, _b) {
        var _this = this;
        var loadingContext = _a.loadingContext;
        var dfpURL = _b.dfpURL;
        if (this.state.status === 'retrievingFingerPrint') {
            return (h("div", { className: "adyen-checkout-risk__device-fingerprint--wrapper", style: { position: 'absolute', width: 0, height: 0 } },
                h(GetDeviceFingerprint, { loadingContext: loadingContext, dfpURL: dfpURL, onCompleteFingerprint: function (fingerprintResult) {
                        _this.setStatusComplete(fingerprintResult);
                    }, onErrorFingerprint: function (fingerprintError) {
                        _this.props.onError(handleErrorCode(fingerprintError.errorCode));
                        _this.setStatusComplete(fingerprintError.result);
                    } })));
        }
        return null;
    };
    DeviceFingerprint.defaultProps = {
        onComplete: function () { },
        onError: function () { }
    };
    return DeviceFingerprint;
}(Component));
export default DeviceFingerprint;
