import { __assign } from "tslib";
import { h } from 'preact';
import classNames from 'classnames';
import { convertFullToHalf } from './utils';
export default function InputBase(props) {
    var autoCorrect = props.autoCorrect, classNameModifiers = props.classNameModifiers, isInvalid = props.isInvalid, isValid = props.isValid, _a = props.readonly, readonly = _a === void 0 ? null : _a, spellCheck = props.spellCheck, type = props.type;
    var handleInput = function (e) {
        e.target.value = convertFullToHalf(e.target.value);
        props.onInput(e);
    };
    var inputClassNames = classNames('adyen-checkout__input', ["adyen-checkout__input--" + type], props.className, {
        'adyen-checkout__input--invalid': isInvalid,
        'adyen-checkout__input--valid': isValid
    }, classNameModifiers.map(function (m) { return "adyen-checkout__input--" + m; }));
    return (h("input", __assign({}, props, { type: type, className: inputClassNames, onInput: handleInput, readOnly: readonly, spellCheck: spellCheck, autoCorrect: autoCorrect })));
}
InputBase.defaultProps = {
    type: 'text',
    classNameModifiers: []
};
