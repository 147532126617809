import { __extends } from "tslib";
import { Component, h } from 'preact';
import './GooglePayButton.scss';
var GooglePayButton = /** @class */ (function (_super) {
    __extends(GooglePayButton, _super);
    function GooglePayButton() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.clicked = false;
        _this.handleClick = function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (!_this.clicked) {
                _this.props.onClick(e);
                _this.clicked = true;
                setTimeout(function () {
                    _this.clicked = false;
                }, 300);
            }
        };
        return _this;
    }
    GooglePayButton.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, buttonColor = _a.buttonColor, buttonType = _a.buttonType, paymentsClient = _a.paymentsClient;
        paymentsClient
            .then(function (client) { return client.createButton({ onClick: _this.handleClick, buttonType: buttonType, buttonColor: buttonColor }); })
            .then(function (googlePayButton) {
            _this.paywithgoogleWrapper.appendChild(googlePayButton);
        });
    };
    GooglePayButton.prototype.render = function () {
        var _this = this;
        return (h("span", { className: 'adyen-checkout__paywithgoogle', ref: function (ref) {
                _this.paywithgoogleWrapper = ref;
            } }));
    };
    GooglePayButton.defaultProps = {
        buttonColor: 'default',
        buttonType: 'long' // long, short
    };
    return GooglePayButton;
}(Component));
export default GooglePayButton;
