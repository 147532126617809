import { AFTERPAY_CONSENT_URL_BE, AFTERPAY_CONSENT_URL_EN, AFTERPAY_CONSENT_URL_NL } from './config';
function getConsentLinkUrl(countryCode, locale) {
    var languageCode = locale === null || locale === void 0 ? void 0 : locale.toLowerCase().slice(0, 2);
    if (languageCode === 'en')
        return AFTERPAY_CONSENT_URL_EN;
    if ((countryCode === null || countryCode === void 0 ? void 0 : countryCode.toLowerCase()) === 'be')
        return AFTERPAY_CONSENT_URL_BE;
    return AFTERPAY_CONSENT_URL_NL;
}
export { getConsentLinkUrl };
