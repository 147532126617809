import { __extends } from "tslib";
import { Component, h } from 'preact';
import classNames from 'classnames';
var ThreeDS2Form = /** @class */ (function (_super) {
    __extends(ThreeDS2Form, _super);
    function ThreeDS2Form() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ThreeDS2Form.prototype.componentDidMount = function () {
        this.formEl.submit();
    };
    ThreeDS2Form.prototype.render = function (_a) {
        var _this = this;
        var name = _a.name, action = _a.action, target = _a.target, inputName = _a.inputName, inputValue = _a.inputValue;
        return (h("form", { ref: function (ref) {
                _this.formEl = ref;
            }, method: "POST", className: classNames(['adyen-checkout__threeds2__form', "adyen-checkout__threeds2__form--" + name]), name: name, action: action, target: target, style: { display: 'none' } },
            h("input", { name: inputName, value: inputValue })));
    };
    return ThreeDS2Form;
}(Component));
export default ThreeDS2Form;
