function isIE() {
    var ua = navigator.userAgent;
    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }
    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }
    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }
    // other browser
    return false;
}
var __IS_ANDROID = /(android)/i.test(navigator.userAgent);
var __IS_IE = isIE();
var __IS_IOS = /iphone|ipod|ipad/i.test(navigator.userAgent);
var __IS_FIREFOX = /(firefox)/i.test(navigator.userAgent);
var __IS_SAFARI = /(safari)/i.test(navigator.userAgent) && !/(chrome)/i.test(navigator.userAgent);
export default {
    __IS_ANDROID: __IS_ANDROID,
    __IS_IE: __IS_IE,
    __IS_IOS: __IS_IOS,
    __IS_FIREFOX: __IS_FIREFOX,
    __IS_SAFARI: __IS_SAFARI
};
