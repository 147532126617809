import { __extends } from "tslib";
import IssuerListContainer from '../helpers/IssuerListContainer';
var MolPayEBankingMYElement = /** @class */ (function (_super) {
    __extends(MolPayEBankingMYElement, _super);
    function MolPayEBankingMYElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MolPayEBankingMYElement.type = 'molpay_ebanking_fpx_MY';
    return MolPayEBankingMYElement;
}(IssuerListContainer));
export default MolPayEBankingMYElement;
