/**
 * @internal
 * Gets an amount value from an amount object or defaults to an amount property.
 */
export function normalizeAmount(props) {
    var _a, _b;
    if (typeof ((_a = props.amount) === null || _a === void 0 ? void 0 : _a.value) !== 'undefined' && ((_b = props.amount) === null || _b === void 0 ? void 0 : _b.currency)) {
        return props.amount;
    }
    else if (typeof props.amount === 'number' && props.currencyCode) {
        return { value: props.amount, currency: props.currencyCode };
    }
    return null;
}
export function resolveSupportedVersion(latestVersion) {
    var versions = [];
    for (var i = latestVersion; i > 0; i--) {
        versions.push(i);
    }
    return versions.find(function (v) { return v && window.ApplePaySession && ApplePaySession.supportsVersion(v); });
}
