var AbstractCSF = /** @class */ (function () {
    // --
    function AbstractCSF(setupObj) {
        this.props = setupObj;
        this.state = {};
        // Initialise storage objects
        this.config = {}; // {} as ConfigObject fails in linting
        this.callbacks = {};
    }
    return AbstractCSF;
}());
export default AbstractCSF;
