var EventsQueue = /** @class */ (function () {
    function EventsQueue() {
        this.events = [];
    }
    EventsQueue.prototype.add = function (event) {
        this.events.push(event);
    };
    EventsQueue.prototype.run = function (conversionId) {
        var promises = this.events.map(function (e) { return e(conversionId); });
        this.events = [];
        return Promise.all(promises);
    };
    return EventsQueue;
}());
export default EventsQueue;
