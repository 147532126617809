import { h } from 'preact';
import { getCardImageUrl } from '../utils';
import useCoreContext from '../../../../../core/Context/useCoreContext';
import styles from '../CardInput.module.scss';
export default function BrandIcon(_a) {
    var brand = _a.brand;
    var loadingContext = useCoreContext().loadingContext;
    var imageName = brand === 'card' ? 'nocard' : brand;
    var handleError = function (e) {
        e.target.style.cssText = 'display: none';
    };
    return (h("img", { className: styles['card-input__icon'] + " adyen-checkout__card__cardNumber__brandIcon", onError: handleError, alt: brand, src: getCardImageUrl(imageName, loadingContext) }));
}
