import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
export default function PaypalButtons(props) {
    var style = props.style, onInit = props.onInit, onComplete = props.onComplete, onClick = props.onClick, onCancel = props.onCancel, onError = props.onError, onSubmit = props.onSubmit, paypalRef = props.paypalRef;
    var paypalButtonRef = useRef(null);
    var creditButtonRef = useRef(null);
    var createButton = function (fundingSource, buttonRef) {
        var button = paypalRef.Buttons({
            fundingSource: fundingSource,
            style: style,
            onInit: onInit,
            onClick: onClick,
            onCancel: onCancel,
            onError: onError,
            createOrder: onSubmit,
            onApprove: onComplete
        });
        if (button.isEligible()) {
            button.render(buttonRef.current);
        }
    };
    useEffect(function () {
        var _a = paypalRef.FUNDING, PAYPAL = _a.PAYPAL, CREDIT = _a.CREDIT;
        createButton(PAYPAL, paypalButtonRef);
        createButton(CREDIT, creditButtonRef);
    }, []);
    return (h("div", { className: "adyen-checkout__paypal__buttons" },
        h("div", { className: "adyen-checkout__paypal__button adyen-checkout__paypal__button--paypal", ref: paypalButtonRef }),
        h("div", { className: "adyen-checkout__paypal__button adyen-checkout__paypal__button--credit", ref: creditButtonRef })));
}
