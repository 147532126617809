import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import EcontextInput from './components/EcontextInput';
import EcontextVoucherResult from './components/EcontextVoucherResult';
import CoreProvider from '../../core/Context/CoreProvider';
import getImage from '../../utils/get-image';
var EcontextElement = /** @class */ (function (_super) {
    __extends(EcontextElement, _super);
    function EcontextElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(EcontextElement.prototype, "isValid", {
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Formats the component data output
     */
    EcontextElement.prototype.formatData = function () {
        return {
            paymentMethod: __assign({ type: this.props.type || EcontextElement.type }, this.state.data)
        };
    };
    Object.defineProperty(EcontextElement.prototype, "icon", {
        get: function () {
            return getImage({ loadingContext: this.props.loadingContext })(this.props.type);
        },
        enumerable: false,
        configurable: true
    });
    EcontextElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext }, this.props.reference ? (h(EcontextVoucherResult, __assign({ ref: function (ref) {
                _this.componentRef = ref;
            } }, this.props))) : (h(EcontextInput, __assign({ ref: function (ref) {
                _this.componentRef = ref;
            } }, this.props, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton })))));
    };
    EcontextElement.type = 'econtext';
    return EcontextElement;
}(UIElement));
export { EcontextElement };
export default EcontextElement;
