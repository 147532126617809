import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import GiftcardInput from './components/GiftcardInput';
import GiftcardResult from './components/GiftcardResult';
import CoreProvider from '../../core/Context/CoreProvider';
import getImage from '../../utils/get-image';
var GiftcardElement = /** @class */ (function (_super) {
    __extends(GiftcardElement, _super);
    function GiftcardElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GiftcardElement.prototype.formatProps = function (props) {
        return props;
    };
    /**
     * Formats the component data output
     */
    GiftcardElement.prototype.formatData = function () {
        return {
            paymentMethod: __assign({ type: this.props.type }, this.state.data)
        };
    };
    Object.defineProperty(GiftcardElement.prototype, "isValid", {
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GiftcardElement.prototype, "icon", {
        get: function () {
            return getImage({ loadingContext: this.props.loadingContext })(this.props.type);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(GiftcardElement.prototype, "displayName", {
        get: function () {
            return this.props.name;
        },
        enumerable: false,
        configurable: true
    });
    GiftcardElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext }, this.props.remainingBalance ? (h(GiftcardResult, __assign({ ref: function (ref) {
                _this.componentRef = ref;
            } }, this.props))) : (h(GiftcardInput, __assign({ ref: function (ref) {
                _this.componentRef = ref;
            } }, this.props, { onChange: this.setState, payButton: this.payButton })))));
    };
    GiftcardElement.type = 'genericgiftcard';
    return GiftcardElement;
}(UIElement));
export { GiftcardElement };
export default GiftcardElement;
