import { __extends } from "tslib";
import { Component, h } from 'preact';
import Iframe from '../../../../components/internal/IFrame';
import promiseTimeout from '../../../../utils/promiseTimeout';
import { DEVICE_FINGERPRINT, DF_TIMEOUT, FAILED_DFP_RESOLVE_OBJECT, FAILED_DFP_RESOLVE_OBJECT_TIMEOUT } from '../../constants';
import getProcessMessageHandler from '../../../../utils/get-process-message-handler';
import { getOrigin } from '../../../../utils/getOrigin';
var iframeName = 'dfIframe';
var allowProperties = 'geolocation; microphone; camera;';
var GetDeviceFingerprint = /** @class */ (function (_super) {
    __extends(GetDeviceFingerprint, _super);
    function GetDeviceFingerprint(props) {
        var _this = _super.call(this, props) || this;
        _this.postMessageDomain = getOrigin(_this.props.loadingContext) || _this.props.loadingContext;
        return _this;
    }
    GetDeviceFingerprint.prototype.getDfpPromise = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            /**
             * Listen for postMessage responses from the notification url
             */
            _this.processMessageHandler = getProcessMessageHandler(_this.postMessageDomain, resolve, reject, FAILED_DFP_RESOLVE_OBJECT, DEVICE_FINGERPRINT);
            /* eslint-disable-next-line */
            window.addEventListener('message', _this.processMessageHandler);
        });
    };
    GetDeviceFingerprint.prototype.componentDidMount = function () {
        var _this = this;
        // Get device fingerprint
        this.deviceFingerPrintPromise = promiseTimeout(DF_TIMEOUT, this.getDfpPromise(), FAILED_DFP_RESOLVE_OBJECT_TIMEOUT);
        this.deviceFingerPrintPromise.promise
            .then(function (resolveObject) {
            _this.props.onCompleteFingerprint(resolveObject);
            window.removeEventListener('message', _this.processMessageHandler);
        })
            .catch(function (rejectObject) {
            _this.props.onErrorFingerprint(rejectObject);
            window.removeEventListener('message', _this.processMessageHandler);
        });
    };
    GetDeviceFingerprint.prototype.render = function (_a) {
        var dfpURL = _a.dfpURL;
        return (h("div", { className: "adyen-checkout-risk__device-fingerprint" },
            h(Iframe, { name: iframeName, src: dfpURL, allow: allowProperties, title: "devicefingerprinting iframe" })));
    };
    return GetDeviceFingerprint;
}(Component));
export default GetDeviceFingerprint;
