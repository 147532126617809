import { h } from 'preact';
import Field from '../../../../internal/FormFields/Field';
import useCoreContext from '../../../../../core/Context/useCoreContext';
import { renderFormField } from '../../../../internal/FormFields';
import styles from '../CardInput.module.scss';
export default function CardHolderName(_a) {
    var onChange = _a.onChange, placeholder = _a.placeholder, value = _a.value, required = _a.required, _b = _a.error, error = _b === void 0 ? false : _b, isValid = _a.isValid;
    var i18n = useCoreContext().i18n;
    return (h(Field, { label: i18n.get('creditCard.holderName'), className: 'adyen-checkout__card__holderName', errorMessage: error && i18n.get('creditCard.holderName.invalid'), isValid: !!isValid }, renderFormField('text', {
        className: "adyen-checkout__card__holderName__input " + styles['adyen-checkout__input'],
        placeholder: placeholder || i18n.get('creditCard.holderName.placeholder'),
        value: value,
        required: required,
        onInput: onChange
    })));
}
