import { on, off, selectOne } from '../../utilities/dom';
import ua from '../../utilities/userAgent';
import * as logger from '../../utilities/logger';
var doLog = false;
var getCaretPos = function (pNode) {
    if ('selectionStart' in pNode) {
        return pNode.selectionStart;
    }
    return 0;
};
function touchendListener(e) {
    if (process.env.NODE_ENV === 'development' && doLog)
        logger.log('### registerAdditionalField::BODY CLICK:: e=', e);
    var targetEl = e.target;
    // If other element is Input or TextArea
    if (targetEl instanceof HTMLInputElement || (HTMLTextAreaElement && targetEl instanceof HTMLTextAreaElement)) {
        // Force caret to show - 'requires' resetting field's value
        var val = targetEl.value;
        var caretPos_1 = getCaretPos(targetEl);
        var adjFlag = false;
        // For some annoying, iOS Safari, reason if caretPos is at the end of the string then it won't show up
        // - so first decrease it; then set it again, asynchronously
        if (caretPos_1 === val.length) {
            caretPos_1 -= 1;
            adjFlag = true;
        }
        targetEl.value = val;
        if (targetEl.setSelectionRange) {
            targetEl.focus();
            targetEl.setSelectionRange(caretPos_1, caretPos_1);
            // Quirky! (see comment above)
            if (adjFlag) {
                caretPos_1 += 1;
                setTimeout(function () {
                    targetEl.setSelectionRange(caretPos_1, caretPos_1);
                }, 0);
            }
        }
    }
    else {
        /**
         * Workaround for iOS/Safari bug where keypad doesn't retract when SF paymentMethod is no longer active
         */
        var hasKeypadFix = this.config.keypadFix; // to avoid linting no-lonely-if
        if (hasKeypadFix) {
            // Create an input we can add focus to.
            // Otherwise 2nd & sub times the caret gets left in the SF even though it has lost focus and cannot be typed into
            var rootNode = this.props.rootNode;
            var nuDiv = document.createElement('input');
            nuDiv.style.width = '1px';
            nuDiv.style.height = '1px';
            nuDiv.style.opacity = '0';
            nuDiv.style.fontSize = '18px'; // prevents zoom
            rootNode.appendChild(nuDiv);
            nuDiv.focus(); // Takes caret from SF
            rootNode.removeChild(nuDiv); // Without this numpad will be replaced with text pad
        }
    }
    // Remove listener
    this.destroyTouchendListener(); // eslint-disable-line no-use-before-define
    // Store the fact we have unset the listener
    this.state.registerFieldForIos = false;
    // Clear focus on secured field inputs now this additional field has gained focus
    this.postMessageToAllIframes({ fieldType: 'additionalField', click: true });
}
function handleAdditionalFields() {
    // Only do for iOS
    if (!ua.__IS_IOS)
        return;
    // This works with the touchend handler, below, to allow us to catch click events on non-securedFields elements.
    // re. http://gravitydept.com/blog/js-click-event-bubbling-on-ios
    // We can use this event to:
    // 1. Set focus on these other elements, and
    // 2. Tell SecuredFields that this has happened so they can blur themselves
    var bodyEl = selectOne(document, 'body');
    bodyEl.style.cursor = 'pointer';
    on(bodyEl, 'touchend', this.touchendListener);
    // Store the fact we have set the listener
    this.state.registerFieldForIos = true;
}
function destroyTouchendListener() {
    if (!ua.__IS_IOS)
        return; // For when fn is called as result of destroy being called on main csf instance
    var bodyEl = selectOne(document, 'body');
    bodyEl.style.cursor = 'auto';
    off(bodyEl, 'touchend', this.touchendListener);
}
export default {
    touchendListener: touchendListener,
    handleAdditionalFields: handleAdditionalFields,
    destroyTouchendListener: destroyTouchendListener
};
