import { __assign, __extends } from "tslib";
import { h } from 'preact';
import OpenInvoiceContainer from '../helpers/OpenInvoiceContainer';
import ConsentCheckboxLabel from './components/ConsentCheckboxLabel';
import { getConsentLinkUrl } from './utils';
var AfterPay = /** @class */ (function (_super) {
    __extends(AfterPay, _super);
    function AfterPay() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AfterPay.prototype.formatProps = function (props) {
        var _a;
        return __assign(__assign({}, _super.prototype.formatProps.call(this, props)), { consentCheckboxLabel: h(ConsentCheckboxLabel, { url: getConsentLinkUrl(props.countryCode, (_a = props.i18n) === null || _a === void 0 ? void 0 : _a.locale) }) });
    };
    AfterPay.type = 'afterpay_default';
    return AfterPay;
}(OpenInvoiceContainer));
export default AfterPay;
