var _a;
export var ERROR_MSG_CARD_TOO_OLD = 'Card too old';
export var ERROR_MSG_CARD_TOO_FAR_IN_FUTURE = 'Date too far in future';
export var ERROR_MSG_CARD_NUMBER_MISMATCH = "Typed card number doesn't match card brand";
export var ERROR_MSG_INCOMPLETE_FIELD = 'incomplete field';
export var ERROR_MSG_LUHN_CHECK_FAILED = 'luhn check failed';
export var ERROR_MSG_UNSUPPORTED_CARD_ENTERED = 'Unsupported card entered';
export var ERROR_MSG_INVALID_FIELD = 'field not valid';
export var ERROR_MSG_CLEARED = 'error was cleared';
export var ERROR_MSG_MBWAY_EMAIL_INVALID = 'Not valid email address';
/**
 * Error Codes
 * @example error.va.sf-cc-num.01
 * =
 * error
 * .validation error
 * .field description
 * .error type (luhn check failed)]
 */
export var ERROR_CODES = (_a = {},
    _a[ERROR_MSG_INCOMPLETE_FIELD] = 'error.va.gen.01',
    _a[ERROR_MSG_INVALID_FIELD] = 'error.va.gen.02',
    _a[ERROR_MSG_LUHN_CHECK_FAILED] = 'error.va.sf-cc-num.01',
    _a[ERROR_MSG_CARD_NUMBER_MISMATCH] = 'error.va.sf-cc-num.02',
    _a[ERROR_MSG_CARD_TOO_OLD] = 'error.va.sf-cc-dat.01',
    _a[ERROR_MSG_CARD_TOO_FAR_IN_FUTURE] = 'error.va.sf-cc-dat.02',
    _a[ERROR_MSG_UNSUPPORTED_CARD_ENTERED] = 'error.va.sf-cc-num.03' // Triggered in Components
,
    _a);
export var DEFAULT_ERROR = ERROR_CODES[ERROR_MSG_INCOMPLETE_FIELD];
