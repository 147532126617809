import { ADDRESS_SCHEMA } from './constants';
/**
 * Generates an object to be used as the initial data.
 * @param data - The data to pre-fill the address fields
 * @param requiredFields - The list of fields to be included in the address form
 */
export var getInitialData = function (data, requiredFields) {
    /**
     * We only want to include the fields if they have pre-filled data or if the field is not required.
     */
    var addressFilter = function (field) { return !!data[field] || !requiredFields.includes(field); };
    /**
     * Creates the data object filling it with either its pre-filled data or `N/A`.
     */
    var addressReducer = function (acc, cur) {
        acc[cur] = data[cur] || 'N/A';
        return acc;
    };
    return ADDRESS_SCHEMA.filter(addressFilter).reduce(addressReducer, {});
};
