import { __assign } from "tslib";
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { renderFormField } from '../../../internal/FormFields';
import Field from '../../../internal/FormFields/Field';
import { isValidEmail } from './validate';
import getIssuerImageUrl from '../../../../utils/get-issuer-image';
import useCoreContext from '../../../../core/Context/useCoreContext';
export default function DragonpayInput(props) {
    var i18n = useCoreContext().i18n;
    var _a = useState(false), isValid = _a[0], setIsValid = _a[1];
    var _b = useState(__assign(__assign({}, props.data), (props.issuer && { issuer: props.issuer }))), data = _b[0], setData = _b[1];
    var _c = useState({}), errors = _c[0], setErrors = _c[1];
    var getIssuerIcon = getIssuerImageUrl({}, props.type);
    var items = props.items.map(function (item) { return (__assign(__assign({}, item), { icon: getIssuerIcon(item.id && item.id.toLowerCase()) })); });
    var isIssuerRequired = function () {
        var typesRequiringIssuers = ['dragonpay_ebanking', 'dragonpay_otc_banking', 'dragonpay_otc_non_banking'];
        return typesRequiringIssuers.indexOf(props.type) > -1;
    };
    var validateFields = function (shopperEmail, issuer) {
        return isValidEmail(shopperEmail) && (!!issuer || !isIssuerRequired());
    };
    var handleInputShopperEmail = function (e) {
        var shopperEmail = e.target.value;
        setIsValid(validateFields(shopperEmail, data.issuer));
        setData(__assign(__assign({}, data), { shopperEmail: shopperEmail }));
        setErrors(__assign(__assign({}, errors), { shopperEmail: false }));
    };
    var handleSelectIssuer = function (e) {
        var issuer = e.currentTarget.getAttribute('data-value');
        setIsValid(validateFields(data.shopperEmail, issuer));
        setData(__assign(__assign({}, data), { issuer: issuer }));
        setErrors(__assign(__assign({}, errors), { issuer: false }));
    };
    var getIssuerSelectFieldKey = function (type) {
        if (type === 'dragonpay_otc_non_banking') {
            return 'dragonpay.voucher.non.bank.selectField.placeholder';
        }
        return 'dragonpay.voucher.bank.selectField.placeholder';
    };
    useEffect(function () {
        if (props.issuer) {
            props.onChange({ isValid: isValid, data: data, errors: errors });
        }
    }, []);
    useEffect(function () {
        props.onChange({ isValid: isValid, data: data, errors: errors });
    }, [isValid, data, errors]);
    var _d = useState('ready'), status = _d[0], setStatus = _d[1];
    this.setStatus = function (newStatus) {
        setStatus(newStatus);
    };
    this.showValidation = function () {
        setErrors({
            shopperEmail: !isValidEmail(data.shopperEmail),
            issuer: !data.issuer
        });
    };
    return (h("div", { className: "adyen-checkout__dragonpay-input__field" },
        h(Field, { label: i18n.get('shopperEmail'), errorMessage: errors.shopperEmail }, renderFormField('emailAddress', {
            name: 'dragonpay.shopperEmail',
            autoCorrect: 'off',
            value: data.shopperEmail,
            className: 'adyen-checkout__input--large',
            spellCheck: false,
            onInput: handleInputShopperEmail
        })),
        isIssuerRequired() && (h(Field, { label: i18n.get(getIssuerSelectFieldKey(props.type)), errorMessage: errors.issuer }, renderFormField('select', {
            items: items,
            selected: data.issuer,
            placeholder: i18n.get(getIssuerSelectFieldKey(props.type)),
            name: 'issuer',
            className: 'adyen-checkout__dropdown--large adyen-checkout__issuer-list__dropdown',
            onChange: handleSelectIssuer
        }))),
        props.showPayButton && props.payButton({ status: status, label: i18n.get('confirmPurchase') })));
}
DragonpayInput.defaultProps = {
    data: {},
    items: [],
    onChange: function () { }
};
