export default {
    isDropin: true,
    onReady: function () { },
    onComplete: function () { },
    onCancel: function () { },
    onError: function () { },
    onSelect: function () { },
    onDisableStoredPaymentMethod: null,
    onChange: function () { },
    onSubmit: function () { },
    onAdditionalDetails: function () { },
    amount: {},
    installmentOptions: {},
    paymentMethodsConfiguration: {},
    openFirstPaymentMethod: true,
    openFirstStoredPaymentMethod: true,
    showStoredPaymentMethods: true,
    showPaymentMethods: true,
    showRemoveStoredPaymentMethodButton: false,
    showPayButton: true // show the default pay button
};
