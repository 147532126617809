export function destroySecuredFields() {
    var _this = this;
    // Tell all securedFields iframes: they will remove all event listeners including keyboard events
    this.postMessageToAllIframes({ destroy: true });
    // Get ref to all the keys under which SecuredField instances are stores
    var securedFieldKeys = Object.keys(this.state.securedFields);
    // Tell each SecuredField instance to:
    // stop listening to message events, remove iframe window refs, remove iframes
    // Then remove ref to SecuredField instance
    securedFieldKeys.forEach(function (pFieldType) {
        var sf = _this.state.securedFields[pFieldType];
        if (sf)
            sf.destroy();
        _this.state.securedFields[pFieldType] = null;
    });
    // --
    // Stop listening to touchend event on body (iOS only)
    this.destroyTouchendListener();
    // Clear SecuredField storage object
    this.state.securedFields = {};
}
