import { __assign } from "tslib";
import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import PersonalDetails from '../../../internal/PersonalDetails/PersonalDetails';
import useCoreContext from '../../../../core/Context/useCoreContext';
import Validator from '../../../../utils/Validator';
import { econtextValidationRules } from '../../validate';
export default function EcontextInput(props) {
    var _a = useState(__assign({}, props.data)), data = _a[0], setData = _a[1];
    var _b = useState(false), isValid = _b[0], setIsValid = _b[1];
    var personalDetailsRef = useRef(null);
    var i18n = useCoreContext().i18n;
    var validator = new Validator(econtextValidationRules);
    useEffect(function () {
        props.onChange({ data: data, isValid: isValid });
    }, [data, isValid]);
    var handleChange = function (state) {
        setData(__assign(__assign({}, data), state.data));
        setIsValid(state.isValid);
    };
    var _c = useState('ready'), status = _c[0], setStatus = _c[1];
    this.setStatus = function (newStatus) {
        setStatus(newStatus);
    };
    this.showValidation = function () {
        if (personalDetailsRef.current)
            personalDetailsRef.current.showValidation();
    };
    return (h("div", { className: "adyen-checkout__econtext-input__field" },
        h(PersonalDetails, { data: data, requiredFields: ['firstName', 'lastName', 'telephoneNumber', 'shopperEmail'], onChange: handleChange, namePrefix: "econtext", ref: personalDetailsRef, validator: validator }),
        props.showPayButton && props.payButton({ status: status, label: i18n.get('confirmPurchase') })));
}
