import { email, telephoneNumber } from '../../../utils/regex';
import { unformatDate } from '../FormFields/InputDate/utils';
var isDateOfBirthValid = function (value) {
    if (!value)
        return false;
    var rawValue = unformatDate(value);
    var ageDiff = Date.now() - Date.parse(rawValue);
    var age = new Date(ageDiff).getFullYear() - 1970;
    return age >= 18;
};
export var personalDetailsValidationRules = {
    blur: {
        default: function (value) {
            var isValid = value && value.length > 0;
            return { isValid: isValid, errorMessage: true };
        },
        dateOfBirth: function (value) {
            return { isValid: isDateOfBirthValid(value), errorMessage: 'dateOfBirth.invalid' };
        },
        telephoneNumber: function (value) {
            return { isValid: telephoneNumber.test(value), errorMessage: true };
        },
        shopperEmail: function (value) {
            return { isValid: email.test(value), errorMessage: true };
        }
    }
};
