import { __assign, __extends } from "tslib";
import { Component, h, createRef } from 'preact';
import Address from '../../../internal/Address';
import CardFields from './components/CardFields';
import CardHolderName from './components/CardHolderName';
import Installments from './components/Installments';
import KCPAuthentication from './components/KCPAuthentication';
import LoadingWrapper from '../../../internal/LoadingWrapper/LoadingWrapper';
import StoredCardFields from './components/StoredCardFields';
import SecuredFieldsProvider from '../../../../components/internal/SecuredFields/SecuredFieldsProvider';
import StoreDetails from '../../../internal/StoreDetails';
import defaultProps from './defaultProps';
import defaultStyles from './defaultStyles';
import getImage from '../../../../utils/get-image';
import handlers from './handlers';
import processBinLookup from './processBinLookup';
import styles from './CardInput.module.scss';
import './CardInput.scss';
var CardInput = /** @class */ (function (_super) {
    __extends(CardInput, _super);
    function CardInput(props) {
        var _this = _super.call(this, props) || this;
        _this.sfp = createRef();
        _this.billingAddressRef = createRef();
        _this.kcpAuthenticationRef = createRef();
        _this.state = __assign(__assign({ status: 'ready', errors: {}, valid: __assign({}, (_this.props.holderNameRequired && { holderName: false })), data: __assign({}, (_this.props.hasHolderName && {
                holderName: _this.props.holderName || _this.props.data.holderName
            })) }, (_this.props.billingAddressRequired && {
            billingAddress: __assign({}, _this.props.data.billingAddress)
        })), { isValid: false, hideCVCForBrand: false, focusedElement: '', additionalSelectElements: [], additionalSelectValue: '', additionalSelectType: '', issuingCountryCode: null });
        _this.validateCardInput = handlers.validateCardInput.bind(_this);
        _this.handleOnBrand = handlers.handleOnBrand.bind(_this);
        _this.handleFocus = handlers.handleFocus.bind(_this);
        _this.handleAddress = handlers.handleAddress.bind(_this);
        _this.handleHolderName = handlers.handleHolderName.bind(_this);
        _this.handleInstallments = handlers.handleInstallments.bind(_this);
        _this.handleKCPAuthentication = handlers.handleKCPAuthentication.bind(_this);
        _this.handleSecuredFieldsChange = handlers.handleSecuredFieldsChange.bind(_this);
        _this.handleOnStoreDetails = handlers.handleOnStoreDetails.bind(_this);
        _this.handleAdditionalDataSelection = handlers.handleAdditionalDataSelection.bind(_this);
        _this.processBinLookup = processBinLookup.bind(_this);
        return _this;
    }
    CardInput.prototype.componentDidMount = function () {
        this.setFocusOn = this.sfp.current.setFocusOn;
        this.updateStyles = this.sfp.current.updateStyles;
        this.handleUnsupportedCard = this.sfp.current.handleUnsupportedCard;
    };
    CardInput.prototype.componentDidUpdate = function (prevProps, prevState) {
        var _a = prevState.billingAddress || {}, prevCountry = _a.country, prevStateOrProvince = _a.stateOrProvince;
        var _b = this.state.billingAddress || {}, country = _b.country, stateOrProvince = _b.stateOrProvince;
        if (prevCountry !== country || prevStateOrProvince !== stateOrProvince) {
            this.validateCardInput();
        }
    };
    CardInput.prototype.componentWillUnmount = function () {
        this.sfp.current.destroy();
        this.sfp = null;
    };
    CardInput.prototype.getChildContext = function () {
        return { i18n: this.props.i18n };
    };
    CardInput.prototype.setStatus = function (status) {
        this.setState({ status: status });
    };
    CardInput.prototype.resetAdditionalSelectState = function () {
        this.setState({
            additionalSelectElements: [],
            additionalSelectValue: '',
            additionalSelectType: ''
        });
    };
    CardInput.prototype.showValidation = function () {
        var _a, _b;
        // Validate SecuredFields
        this.sfp.current.showValidation();
        // Validate holderName
        if (this.props.holderNameRequired && !this.state.valid.holderName) {
            this.setState(function (prevState) { return ({
                errors: __assign(__assign({}, prevState.errors), { holderName: true })
            }); });
        }
        // Validate Address
        if ((_a = this.billingAddressRef) === null || _a === void 0 ? void 0 : _a.current)
            this.billingAddressRef.current.showValidation();
        // Validate KCP authentication
        if ((_b = this.kcpAuthenticationRef) === null || _b === void 0 ? void 0 : _b.current)
            this.kcpAuthenticationRef.current.showValidation();
    };
    CardInput.prototype.processBinLookupResponse = function (data) {
        var _this = this;
        var issuingCountryCode = (data === null || data === void 0 ? void 0 : data.issuingCountryCode) ? data.issuingCountryCode.toLowerCase() : null;
        this.setState({ issuingCountryCode: issuingCountryCode }, function () {
            _this.processBinLookup(data);
        });
    };
    CardInput.prototype.render = function (_a, _b) {
        var _this = this;
        var countryCode = _a.countryCode, loadingContext = _a.loadingContext, hasHolderName = _a.hasHolderName, hasCVC = _a.hasCVC, installmentOptions = _a.installmentOptions, enableStoreDetails = _a.enableStoreDetails, showInstallmentAmounts = _a.showInstallmentAmounts;
        var status = _b.status, hideCVCForBrand = _b.hideCVCForBrand, focusedElement = _b.focusedElement, issuingCountryCode = _b.issuingCountryCode;
        var hasInstallments = !!Object.keys(installmentOptions).length;
        // In the Drop-in the oneClick status may already have been decided, so give that priority
        var isOneClick = this.props.oneClick || !!this.props.storedPaymentMethodId;
        // If issuingCountryCode is set or the merchant defined countryCode is 'KR'
        var isKorea = issuingCountryCode ? issuingCountryCode === 'kr' : countryCode === 'kr';
        var showAmountsInInstallments = showInstallmentAmounts !== null && showInstallmentAmounts !== void 0 ? showInstallmentAmounts : true;
        return (h(SecuredFieldsProvider, __assign({ ref: this.sfp }, this.props, { styles: __assign(__assign({}, defaultStyles), this.props.styles), onChange: this.handleSecuredFieldsChange, onBrand: this.handleOnBrand, onFocus: this.handleFocus, type: this.props.brand, oneClick: isOneClick, render: function (_a, sfpState) {
                var setRootNode = _a.setRootNode, setFocusOn = _a.setFocusOn;
                return (h("div", { ref: setRootNode, className: "adyen-checkout__card-input " + styles['card-input__wrapper'] },
                    _this.props.storedPaymentMethodId ? (h(LoadingWrapper, { status: sfpState.status },
                        h(StoredCardFields, __assign({}, _this.props, { cvcRequired: sfpState.cvcRequired, errors: sfpState.errors, brand: sfpState.brand, hasCVC: hasCVC, hideCVCForBrand: hideCVCForBrand, onFocusField: setFocusOn, focusedElement: focusedElement, status: sfpState.status, valid: sfpState.valid })),
                        hasInstallments && (h(Installments, { amount: _this.props.amount, brand: sfpState.brand, installmentOptions: installmentOptions, onChange: _this.handleInstallments, type: showAmountsInInstallments ? 'amount' : 'months' })))) : (h(LoadingWrapper, { status: sfpState.status },
                        h(CardFields, __assign({}, _this.props, { brand: sfpState.brand, focusedElement: focusedElement, onFocusField: setFocusOn, hasCVC: hasCVC, hideCVCForBrand: hideCVCForBrand, errors: sfpState.errors, valid: sfpState.valid, cvcRequired: sfpState.cvcRequired, dualBrandingElements: _this.state.additionalSelectElements.length > 0 && _this.state.additionalSelectElements, dualBrandingChangeHandler: _this.handleAdditionalDataSelection, dualBrandingSelected: _this.state.additionalSelectValue })),
                        hasHolderName && (h(CardHolderName, { required: _this.props.holderNameRequired, placeholder: _this.props.placeholders.holderName, value: _this.state.data.holderName, error: !!_this.state.errors.holderName, isValid: !!_this.state.valid.holderName, onChange: _this.handleHolderName })),
                        _this.props.configuration.koreanAuthenticationRequired && isKorea && (h(KCPAuthentication, { onFocusField: setFocusOn, focusedElement: focusedElement, encryptedPasswordState: {
                                data: sfpState.encryptedPassword,
                                valid: sfpState.valid ? sfpState.valid.encryptedPassword : false,
                                errors: sfpState.errors ? sfpState.errors.encryptedPassword : false
                            }, ref: _this.kcpAuthenticationRef, onChange: _this.handleKCPAuthentication })),
                        enableStoreDetails && h(StoreDetails, { onChange: _this.handleOnStoreDetails }),
                        hasInstallments && (h(Installments, { amount: _this.props.amount, brand: sfpState.brand, installmentOptions: installmentOptions, onChange: _this.handleInstallments, type: showAmountsInInstallments ? 'amount' : 'months' })),
                        _this.props.billingAddressRequired && (h(Address, { label: "billingAddress", data: _this.state.billingAddress, onChange: _this.handleAddress, allowedCountries: _this.props.billingAddressAllowedCountries, requiredFields: _this.props.billingAddressRequiredFields, ref: _this.billingAddressRef })))),
                    _this.props.showPayButton &&
                        _this.props.payButton({ status: status, icon: getImage({ loadingContext: loadingContext, imageFolder: 'components/' })('lock') })));
            } })));
    };
    CardInput.defaultProps = defaultProps;
    return CardInput;
}(Component));
export default CardInput;
