import { __assign } from "tslib";
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import Fieldset from '../FormFields/Fieldset';
import Field from '../FormFields/Field';
import ReadOnlyCompanyDetails from './ReadOnlyCompanyDetails';
import { renderFormField } from '../FormFields';
import { companyDetailsValidationRules } from './validate';
import Validator from '../../../utils/Validator';
import useCoreContext from '../../../core/Context/useCoreContext';
var companyDetailsSchema = ['name', 'registrationNumber'];
export default function CompanyDetails(props) {
    var _a = props.label, label = _a === void 0 ? '' : _a, namePrefix = props.namePrefix, requiredFields = props.requiredFields, visibility = props.visibility, validator = props.validator;
    var i18n = useCoreContext().i18n;
    var _b = useState(props.data), data = _b[0], setData = _b[1];
    var _c = useState({}), errors = _c[0], setErrors = _c[1];
    var _d = useState({}), valid = _d[0], setValid = _d[1];
    var eventHandler = function (mode) { return function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var key = name.split(namePrefix + ".").pop();
        var _b = validator.validate(key, mode)(value), isValid = _b.isValid, errorMessage = _b.errorMessage;
        setData(function (prevData) {
            var _a;
            return (__assign(__assign({}, prevData), (_a = {}, _a[key] = value, _a)));
        });
        setValid(function (prevValid) {
            var _a;
            return (__assign(__assign({}, prevValid), (_a = {}, _a[key] = isValid, _a)));
        });
        setErrors(function (prevErrors) {
            var _a;
            return (__assign(__assign({}, prevErrors), (_a = {}, _a[key] = !isValid && errorMessage, _a)));
        });
    }; };
    var generateFieldName = function (name) { return "" + (namePrefix ? namePrefix + "." : '') + name; };
    useEffect(function () {
        var isValid = requiredFields.every(function (field) { return validator.validate(field, 'blur')(data[field]).isValid; });
        props.onChange({ data: data, isValid: isValid });
    }, [data, valid, errors]);
    this.showValidation = function () {
        var errorsReducer = function (acc, field) {
            var _a = validator.validate(field, 'blur')(data[field]), isValid = _a.isValid, errorMessage = _a.errorMessage;
            acc[field] = !isValid && errorMessage;
            return acc;
        };
        setErrors(requiredFields.reduce(errorsReducer, {}));
    };
    if (visibility === 'hidden')
        return null;
    if (visibility === 'readOnly')
        return h(ReadOnlyCompanyDetails, __assign({}, props, { data: data }));
    return (h(Fieldset, { classNameModifiers: [label], label: label },
        requiredFields.includes('name') && (h(Field, { label: i18n.get('companyDetails.name'), classNameModifiers: ['name'], errorMessage: !!errors.name }, renderFormField('text', {
            name: generateFieldName('name'),
            value: data.name,
            classNameModifiers: ['name'],
            onInput: eventHandler('input'),
            onChange: eventHandler('blur'),
            spellCheck: false
        }))),
        requiredFields.includes('registrationNumber') && (h(Field, { label: i18n.get('companyDetails.registrationNumber'), classNameModifiers: ['registrationNumber'], errorMessage: !!errors.registrationNumber }, renderFormField('text', {
            name: generateFieldName('registrationNumber'),
            value: data.registrationNumber,
            classNameModifiers: ['registrationNumber'],
            onInput: eventHandler('input'),
            onChange: eventHandler('blur'),
            spellCheck: false
        })))));
}
CompanyDetails.defaultProps = {
    data: {},
    onChange: function () { },
    visibility: 'editable',
    requiredFields: companyDetailsSchema,
    validator: new Validator(companyDetailsValidationRules)
};
