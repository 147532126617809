import { __assign, __rest } from "tslib";
import { h } from 'preact';
import cx from 'classnames';
import './Checkbox.scss';
export default function Checkbox(_a) {
    var _b = _a.classNameModifiers, classNameModifiers = _b === void 0 ? [] : _b, label = _a.label, isInvalid = _a.isInvalid, onChange = _a.onChange, props = __rest(_a, ["classNameModifiers", "label", "isInvalid", "onChange"]);
    return (h("label", { className: "adyen-checkout__checkbox" },
        h("input", __assign({}, props, { className: cx([
                'adyen-checkout__checkbox__input',
                [props.className],
                { 'adyen-checkout__checkbox__input--invalid': isInvalid },
                classNameModifiers.map(function (m) { return "adyen-checkout__input--" + m; })
            ]), type: "checkbox", onChange: onChange })),
        h("span", { className: "adyen-checkout__checkbox__label" }, label)));
}
Checkbox.defaultProps = {
    onChange: function () { }
};
