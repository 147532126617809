import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import IssuerList from '../internal/IssuerList';
import getIssuerImageUrl from '../../utils/get-issuer-image';
import { FALLBACK_CONTEXT } from '../../core/config';
import CoreProvider from '../../core/Context/CoreProvider';
var IssuerListContainer = /** @class */ (function (_super) {
    __extends(IssuerListContainer, _super);
    function IssuerListContainer(props) {
        var _this = _super.call(this, props) || this;
        if (_this.props.showImage) {
            var getIssuerIcon_1 = getIssuerImageUrl({ loadingContext: _this.props.loadingContext }, _this.constructor['type']);
            _this.props.items = _this.props.items.map(function (item) { return (__assign(__assign({}, item), { icon: getIssuerIcon_1(item.id) })); });
        }
        return _this;
    }
    /**
     * Formats props on construction time
     */
    IssuerListContainer.prototype.formatProps = function (props) {
        return __assign(__assign({}, props), { items: props.details && props.details.length ? (props.details.find(function (d) { return d.key === 'issuer'; }) || {}).items : props.items });
    };
    /**
     * Formats the component data output
     */
    IssuerListContainer.prototype.formatData = function () {
        return {
            paymentMethod: {
                type: this.constructor['type'],
                issuer: this.state.issuer
            }
        };
    };
    Object.defineProperty(IssuerListContainer.prototype, "isValid", {
        /**
         * Returns whether the component state is valid or not
         */
        get: function () {
            var _a;
            return !!((_a = this.state) === null || _a === void 0 ? void 0 : _a.issuer);
        },
        enumerable: false,
        configurable: true
    });
    IssuerListContainer.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, { i18n: this.props.i18n, loadingContext: this.props.loadingContext },
            h(IssuerList, __assign({ ref: function (ref) {
                    _this.componentRef = ref;
                } }, this.props, this.state, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton }))));
    };
    IssuerListContainer.defaultProps = {
        showImage: true,
        onValid: function () { },
        items: [],
        loadingContext: FALLBACK_CONTEXT
    };
    return IssuerListContainer;
}(UIElement));
export default IssuerListContainer;
