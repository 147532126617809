import { h } from 'preact';
import Fieldset from '../FormFields/Fieldset';
var ReadOnlyCompanyDetails = function (_a) {
    var data = _a.data;
    var name = data.name, registrationNumber = data.registrationNumber;
    return (h(Fieldset, { classNameModifiers: ['companyDetails'], label: "companyDetails", readonly: true },
        name && name + " ",
        registrationNumber && registrationNumber + " "));
};
export default ReadOnlyCompanyDetails;
