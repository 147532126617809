import { __assign, __extends } from "tslib";
import { h } from 'preact';
import UIElement from '../UIElement';
import IbanInput from './components/IbanInput';
import CoreProvider from '../../core/Context/CoreProvider';
/**
 * SepaElement
 */
var SepaElement = /** @class */ (function (_super) {
    __extends(SepaElement, _super);
    function SepaElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Formats props on construction time
     */
    SepaElement.prototype.formatProps = function (props) {
        return __assign({ holderName: true }, props);
    };
    /**
     * Formats the component data output
     */
    SepaElement.prototype.formatData = function () {
        return {
            paymentMethod: __assign({ type: SepaElement.type }, this.state.data)
        };
    };
    Object.defineProperty(SepaElement.prototype, "isValid", {
        /**
         * Returns whether the component state is valid or not
         */
        get: function () {
            return !!this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    SepaElement.prototype.render = function () {
        var _this = this;
        return (h(CoreProvider, __assign({}, this.props, { loadingContext: this.props.loadingContext }),
            h(IbanInput, __assign({ ref: function (ref) {
                    _this.componentRef = ref;
                } }, this.props, { onChange: this.setState, onSubmit: this.submit, payButton: this.payButton }))));
    };
    SepaElement.type = 'sepadirectdebit';
    return SepaElement;
}(UIElement));
export default SepaElement;
