import { __extends } from "tslib";
import IssuerListContainer from '../helpers/IssuerListContainer';
var OpenBankingUKElement = /** @class */ (function (_super) {
    __extends(OpenBankingUKElement, _super);
    function OpenBankingUKElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OpenBankingUKElement.type = 'openbanking_UK';
    return OpenBankingUKElement;
}(IssuerListContainer));
export default OpenBankingUKElement;
