/**
 *
 */
export function resolveEnvironment(env) {
    if (env === void 0) { env = 'TEST'; }
    var environment = env.toLowerCase();
    switch (environment) {
        case 'production':
        case 'live':
            return 'PRODUCTION';
        default:
            return 'TEST';
    }
}
