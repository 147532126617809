import { __assign, __extends } from "tslib";
import { Component, h } from 'preact';
import { getTimeDifference } from './utils';
var Countdown = /** @class */ (function (_super) {
    __extends(Countdown, _super);
    function Countdown(props) {
        var _this = _super.call(this, props) || this;
        var secondsFromNow = _this.props.minutesFromNow * 60000;
        var nowTime = new Date().getTime();
        _this.state = {
            startTime: new Date(nowTime),
            endTime: new Date(nowTime + secondsFromNow),
            minutes: '-',
            seconds: '-'
        };
        return _this;
    }
    Countdown.prototype.tick = function () {
        var newTime = getTimeDifference(this.state.startTime, this.state.endTime);
        if (newTime.completed) {
            this.props.onCompleted();
            return this.clearInterval();
        }
        var time = {
            minutes: newTime.minutes,
            seconds: newTime.seconds,
            percentage: newTime.percentage
        };
        this.setState(__assign({}, time));
        this.props.onTick(time);
        return time;
    };
    Countdown.prototype.clearInterval = function () {
        clearInterval(this.interval);
        delete this.interval;
    };
    Countdown.prototype.componentDidMount = function () {
        var _this = this;
        this.interval = setInterval(function () {
            _this.tick();
        }, 1000);
    };
    Countdown.prototype.componentWillUnmount = function () {
        this.clearInterval();
    };
    Countdown.prototype.render = function () {
        return (h("span", { className: "adyen-checkout__countdown" },
            h("span", { className: "countdown__minutes" }, this.state.minutes),
            h("span", { className: "countdown__separator" }, ":"),
            h("span", { className: "countdown__seconds" }, this.state.seconds)));
    };
    Countdown.defaultProps = {
        onTick: function () { },
        onCompleted: function () { }
    };
    return Countdown;
}(Component));
export default Countdown;
