import { h } from 'preact';
import Voucher from '../../../internal/Voucher';
import getIssuerImageUrl from '../../../../utils/get-issuer-image';
import useCoreContext from '../../../../core/Context/useCoreContext';
export default function DragonpayVoucherResult(props) {
    var reference = props.reference, totalAmount = props.totalAmount, surcharge = props.surcharge, expiresAt = props.expiresAt, alternativeReference = props.alternativeReference, instructionsUrl = props.instructionsUrl, icon = props.icon, issuer = props.issuer, paymentMethodType = props.paymentMethodType;
    var _a = useCoreContext(), loadingContext = _a.loadingContext, i18n = _a.i18n;
    var issuerImageUrl = getIssuerImageUrl({ loadingContext: loadingContext }, paymentMethodType)(issuer.toLowerCase());
    return (h(Voucher, { reference: reference, paymentMethodType: paymentMethodType, introduction: i18n.get('voucher.introduction'), imageUrl: icon, issuerImageUrl: issuerImageUrl, instructionsUrl: instructionsUrl, amount: totalAmount && i18n.amount(totalAmount.value, totalAmount.currency), surcharge: surcharge && i18n.amount(surcharge.value, surcharge.currency), voucherDetails: [
            { label: i18n.get('voucher.expirationDate'), value: i18n.date(expiresAt) },
            { label: i18n.get('voucher.alternativeReference'), value: alternativeReference }
        ], copyBtn: true }));
}
