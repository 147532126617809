import actionTypes from './actionTypes';
export function getComponentForAction(action, props) {
    if (props === void 0) { props = {}; }
    var nextAction = actionTypes[action.type];
    if (nextAction && typeof nextAction === 'function') {
        return nextAction(action, props);
    }
    throw new Error('Invalid Action');
}
export default getComponentForAction;
