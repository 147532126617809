import { __assign, __extends } from "tslib";
import { Component, h } from 'preact';
import Do3DS2Challenge from './Do3DS2Challenge';
import { createResolveData, handleErrorCode, encodeResult, prepareChallengeData } from '../utils';
import '../../ThreeDS2.scss';
var ThreeDS2Challenge = /** @class */ (function (_super) {
    __extends(ThreeDS2Challenge, _super);
    function ThreeDS2Challenge(props) {
        var _this = _super.call(this, props) || this;
        if (_this.props.challengeToken) {
            var challengeData = prepareChallengeData({
                challengeToken: _this.props.challengeToken,
                size: _this.props.size,
                notificationURL: _this.props.notificationURL
            });
            _this.state = {
                status: 'retrievingChallengeToken',
                challengeData: challengeData
            };
        }
        else {
            _this.state = { status: 'error' };
            _this.props.onError('Missing challengeToken parameter');
        }
        return _this;
    }
    ThreeDS2Challenge.prototype.setStatusComplete = function (resultObj) {
        var _this = this;
        this.setState({ status: 'complete' }, function () {
            var paymentData = _this.props.paymentData;
            var result = encodeResult(resultObj, _this.props.type);
            var data = createResolveData(_this.props.dataKey, result, paymentData);
            _this.props.onComplete(data);
        });
    };
    ThreeDS2Challenge.prototype.render = function (props, _a) {
        var _this = this;
        var challengeData = _a.challengeData;
        if (this.state.status === 'retrievingChallengeToken') {
            return (h(Do3DS2Challenge, __assign({ onCompleteChallenge: function (challenge) {
                    _this.setStatusComplete(challenge.result);
                }, onErrorChallenge: function (challenge) {
                    var errorObject = handleErrorCode(challenge.errorCode);
                    _this.props.onError(errorObject);
                    _this.setStatusComplete(challenge.result);
                } }, challengeData)));
        }
        return null;
    };
    ThreeDS2Challenge.defaultProps = {
        onComplete: function () { },
        onError: function () { }
    };
    return ThreeDS2Challenge;
}(Component));
export default ThreeDS2Challenge;
