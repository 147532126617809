import { __assign, __extends } from "tslib";
import IssuerListContainer from '../helpers/IssuerListContainer';
var BillDeskOnlineElement = /** @class */ (function (_super) {
    __extends(BillDeskOnlineElement, _super);
    function BillDeskOnlineElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BillDeskOnlineElement.prototype.formatProps = function (props) {
        return __assign(__assign({}, _super.prototype.formatProps.call(this, props)), { showImage: false });
    };
    BillDeskOnlineElement.type = 'billdesk_online';
    return BillDeskOnlineElement;
}(IssuerListContainer));
export default BillDeskOnlineElement;
