import { __assign, __extends } from "tslib";
import { h } from 'preact';
import BaseElement from '../../components/BaseElement';
import DeviceFingerprint from './components/DeviceFingerprint';
import base64 from '../../utils/base64';
import { RISK_DATA_VERSION, DEVICE_FINGERPRINT } from './constants';
var RiskElement = /** @class */ (function (_super) {
    __extends(RiskElement, _super);
    function RiskElement(props) {
        var _a;
        var _this = _super.call(this, props) || this;
        _this.nodeRiskContainer = null;
        _this.onComplete = function (result) {
            var _a;
            var data = __assign(__assign({}, _this.state.data), (_a = {}, _a[result.type] = result.value, _a.persistentCookie = result.persistentCookie, _a.components = result.components, _a));
            _this.setState({ data: data, isValid: true });
            _this.props.risk.onComplete(_this.data);
            _this.cleanUp();
        };
        _this.onError = function (error) {
            _this.props.risk.onError(error);
            _this.cleanUp();
        };
        _this.cleanUp = function () {
            if (_this.nodeRiskContainer)
                _this.nodeRiskContainer.remove();
        };
        // Populate state with null values
        var riskElements = (_a = {},
            _a[DEVICE_FINGERPRINT] = null,
            _a);
        _this.setState({ data: riskElements });
        if (_this.props.risk.enabled === true) {
            if (document.querySelector(_this.props.risk.node)) {
                _this.nodeRiskContainer = document.createElement('div');
                document.querySelector(_this.props.risk.node).appendChild(_this.nodeRiskContainer);
                _this.mount(_this.nodeRiskContainer);
            }
            else {
                _this.onError({ message: 'RiskModule node was not found' });
            }
        }
        return _this;
    }
    RiskElement.prototype.formatProps = function (props) {
        return __assign(__assign({}, props), { risk: __assign(__assign({}, RiskElement.defaultProps.risk), props.risk) });
    };
    Object.defineProperty(RiskElement.prototype, "isValid", {
        get: function () {
            return this.state.isValid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(RiskElement.prototype, "data", {
        get: function () {
            if (this.isValid) {
                var dataObj = __assign({ version: RISK_DATA_VERSION }, this.state.data);
                return base64.encode(JSON.stringify(dataObj));
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    RiskElement.prototype.componentWillUnmount = function () {
        this.cleanUp();
    };
    RiskElement.prototype.render = function () {
        return h(DeviceFingerprint, __assign({}, this.props, { loadingContext: this.props.loadingContext, onComplete: this.onComplete, onError: this.onError }));
    };
    RiskElement.type = 'risk';
    RiskElement.defaultProps = {
        risk: {
            enabled: true,
            onComplete: function () { },
            onError: function () { },
            node: 'body'
        }
    };
    return RiskElement;
}(BaseElement));
export default RiskElement;
