import { __assign } from "tslib";
import Language from '../language';
import UIElement from '../components/UIElement';
import RiskModule from './RiskModule';
import paymentMethods, { getComponentConfiguration } from '../components';
import PaymentMethodsResponse from './ProcessResponse/PaymentMethodsResponse';
import getComponentForAction from './ProcessResponse/PaymentAction';
import resolveEnvironment from './Environment';
import Analytics from './Analytics';
var Core = /** @class */ (function () {
    function Core(options) {
        if (options === void 0) { options = {}; }
        this.options = __assign(__assign({}, options), { loadingContext: resolveEnvironment(options.environment) });
        this.modules = {
            risk: new RiskModule(this.options),
            analytics: new Analytics(this.options),
            i18n: new Language(options.locale, options.translations)
        };
        this.paymentMethodsResponse = new PaymentMethodsResponse(options.paymentMethodsResponse, options);
        this.create = this.create.bind(this);
        this.createFromAction = this.createFromAction.bind(this);
    }
    Core.prototype.create = function (paymentMethod, options) {
        var props = this.getPropsForComponent(options);
        return paymentMethod ? this.handleCreate(paymentMethod, props) : this.handleCreateError();
    };
    /**
     * Instantiates a new element component ready to be mounted from an action object
     * @param action - action defining the component with the component data
     * @param options - options that will be merged to the global Checkout props
     * @returns new UIElement
     */
    Core.prototype.createFromAction = function (action, options) {
        if (options === void 0) { options = {}; }
        if (action.type) {
            var props = this.getPropsForComponent(options);
            return getComponentForAction(action, props);
        }
        return this.handleCreateError();
    };
    /**
     * @param options - options that will be merged to the global Checkout props
     * @returns props for a new UIElement
     */
    Core.prototype.getPropsForComponent = function (options) {
        return __assign(__assign(__assign({ paymentMethods: this.paymentMethodsResponse.paymentMethods, storedPaymentMethods: this.paymentMethodsResponse.storedPaymentMethods }, this.options), options), { i18n: this.modules.i18n, modules: this.modules, createFromAction: this.createFromAction });
    };
    /**
     * @internal
     */
    Core.prototype.handleCreate = function (PaymentMethod, options) {
        if (options === void 0) { options = {}; }
        var isValidClass = PaymentMethod.prototype instanceof UIElement;
        /**
         * Once we receive a valid class for a Component - create a new instance of it
         */
        if (isValidClass) {
            var paymentMethodsDetails = !options.supportedShopperInteractions ? this.paymentMethodsResponse.find(PaymentMethod.type) : [];
            // NOTE: will only have a value if a paymentMethodsConfiguration object is defined at top level, in the config object set when a
            // new AdyenCheckout is initialised.
            var paymentMethodsConfiguration = getComponentConfiguration(PaymentMethod.type, options.paymentMethodsConfiguration);
            // Merge:
            // 1. props defined on the PaymentMethod in the response object (will not have a value for the 'dropin' component)
            // 2. the combined props of checkout & the configuration object defined on this particular component
            // 3. a paymentMethodsConfiguration object, if defined at top level
            return new PaymentMethod(__assign(__assign(__assign({}, paymentMethodsDetails), options), paymentMethodsConfiguration));
        }
        /**
         * Most common use case. Usual initial point of entry to this function.
         * When PaymentMethod is defined as a string - retrieve a component from the componentsMap and recall this function passing in a valid class
         */
        if (typeof PaymentMethod === 'string' && paymentMethods[PaymentMethod]) {
            return this.handleCreate(paymentMethods[PaymentMethod], options);
        }
        /**
         * If we are trying to create a payment method that is in the paymentMethodsResponse & it doesn't require any details - treat it as a redirect
         */
        if (typeof PaymentMethod === 'string' &&
            this.paymentMethodsResponse.has(PaymentMethod) &&
            !this.paymentMethodsResponse.find(PaymentMethod).details) {
            var paymentMethodsConfiguration = getComponentConfiguration(PaymentMethod, options.paymentMethodsConfiguration);
            return this.handleCreate(paymentMethods.redirect, __assign(__assign(__assign({}, this.paymentMethodsResponse.find(PaymentMethod)), options), paymentMethodsConfiguration));
        }
        return this.handleCreateError(PaymentMethod);
    };
    /**
     * @internal
     */
    Core.prototype.handleCreateError = function (paymentMethod) {
        var paymentMethodName = paymentMethod && paymentMethod.name ? paymentMethod.name : 'The passed payment method';
        var errorMessage = paymentMethod ? paymentMethodName + " is not a valid Checkout Component" : 'No Payment Method component was passed';
        throw new Error(errorMessage);
    };
    Core.version = {
        version: process.env.VERSION,
        revision: process.env.COMMIT_HASH,
        branch: process.env.COMMIT_BRANCH,
        buildId: process.env.ADYEN_BUILD_ID
    };
    return Core;
}());
export default Core;
