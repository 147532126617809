import { __extends } from "tslib";
import IssuerListContainer from '../helpers/IssuerListContainer';
var EntercashElement = /** @class */ (function (_super) {
    __extends(EntercashElement, _super);
    function EntercashElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EntercashElement.type = 'entercash';
    return EntercashElement;
}(IssuerListContainer));
export default EntercashElement;
